import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ChangeDetectorRef } from '@angular/core';
import  { SiteSubjectStoreService } from 'src/app/store/services/site-subject-store.service';
import { VisitFormStoreService } from 'src/app/store/services/visit-form-store.service';
import type { ResponseSubject } from 'src/app/models/response/responseSiteSubject';
import type { ResponseVisitForm } from 'src/app/models/response/responseVisitForm';
import { Role } from 'src/app/models/Role';
import type { ResponseField } from 'src/app/models/response/responseField';
import type { ResponseUser } from 'src/app/models/response/responseUser';
import { FieldStoreService } from 'src/app/store/services/field-store.service';
import { UserStoreService } from 'src/app/store/services/user-store.service';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'dynamic-id-table',
  templateUrl: './dynamic-id-table.component.html',
  styleUrls: ['./dynamic-id-table.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class DynamicIdTableComponent {

  columnHeadingList: string[] = [];
  @Input() isPagination: boolean = false;
  @Input() columnList: Array<string> = [];
  @Input() idRowData: Array<any> = [];

  @Input() page: number = 1;
  @Input() totalPages: number = 1;
  @Input() isApiRunning: boolean = false;

  @Input() isSiteColumn: boolean = false;
  @Input() isSubjectColumn: boolean = false;
  @Input() isVisitColumn: boolean = false;
  @Input() isFormColumn: boolean = false;

  @Input() isShowUpload: boolean = false;
  @Input() isShowAction: boolean = false;
  @Input() isShowDownload: boolean = false;
  @Input() isShowDelete: boolean = false;
  @Input() isShowPreview: boolean = false;

  @Output() onPaginationChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDownloadClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDeleteClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onPreviewClick: EventEmitter<any> = new EventEmitter<any>();
  siteList: any[] = [];
  subjectList: any[] = [];
  visitList: any[] = [];
  formList: any[] = [];

  fieldList: ResponseField[] = [];

  userList: ResponseUser[] = [];
  crc: number = Role.CRC;
  roles: number = -1;
  studyDateFormat: any;

  constructor( private changeDetectorRef: ChangeDetectorRef,
                private siteSubjectStoreService: SiteSubjectStoreService,
                private visitFormStoreService: VisitFormStoreService,
                private fieldStoreService: FieldStoreService,
                private userStoreService: UserStoreService) {
      }

  ngOnInit(): void {
    this.roles = Number(sessionStorage.getItem('role'))
    this.subscribeFieldDataStore();
    this.subscribeSiteSubjectStore();
    this.subscribeVisitFormStore();
    this.subscribeLogFormStore();
    this.subscribeUserStore();
    this.subscribeUserStore();
    this.studyDateFormat = sessionStorage.getItem("studyDateFormat");
  }

  setDateByDateFormat(date: any): string {
    let datePipe:DatePipe = new DatePipe('EN')
    let dateFormatted:any = datePipe.transform(date,this.studyDateFormat)
    return dateFormatted?.toString();
  }

  subscribeFieldDataStore() {
    this.fieldStoreService.fieldData$.subscribe((data) => {
      this.fieldList = data;
      this.changeDetectorRef.detectChanges();
    });
  }

  subscribeLogFormStore() {
    this.visitFormStoreService.logForms$.subscribe((data) => {
      this.extractVisitFormData(data);
      this.changeDetectorRef.detectChanges(); 
    });
  }

  subscribeVisitFormStore() {
    this.visitFormStoreService.visitForms$.subscribe((data) => {
      this.extractVisitFormData(data);
      this.changeDetectorRef.detectChanges();
    });
  }

  subscribeUserStore() {
    this.userStoreService.userData$.subscribe((data) => {
      this.userList = data;
      this.changeDetectorRef.detectChanges();
    });
  }

  subscribeSiteSubjectStore() {
  this.siteSubjectStoreService.siteSubjects$.subscribe((data) => {
      this.extractSiteSubjectData(data);
      this.changeDetectorRef.detectChanges();
    });
  }

  extractSiteSubjectData(data: any): void {
    if (!data?.sites) {
        return;
    }

  const siteList: { id: number; name: string }[] = [];
    let subjectList: ResponseSubject[] = [];

    for (const [siteId, siteData] of Object.entries(data.sites)) {
        const siteDataTyped = siteData as { name?: string; subjects?: ResponseSubject[] };
        siteList.push({ id: Number(siteId), name: siteDataTyped.name || '' });

        if (Array.isArray((siteData as { subjects?: ResponseSubject[] }).subjects)) {
            subjectList.push(
                ...(siteData as { subjects: ResponseSubject[] }).subjects.map((subject: ResponseSubject) => ({
                    ...subject,
                    site: {
                         siteId: Number(siteId),
                        siteName: (siteData as { name?: string }).name || '',
                    },
                }))
            );
        }
    }

    this.siteList = siteList;
    this.subjectList = subjectList;
  }

  extractVisitFormData(data: Map<string, ResponseVisitForm> | any): void {
    if (!data?.visit || typeof data !== 'object') {
        return;
    }

    const newVisitList = Object.entries(data.visit).map(([visitId, visit]: [string, any]) => ({
        id: visitId,
        name: visit.name || '',
        forms: visit.forms || []
    }));


    this.visitList = [...this.visitList, ...newVisitList];

    const newFormList = newVisitList.reduce((acc, visit) => {
        const forms = data.visit[visit.id]?.forms || [];
        return acc.concat(forms);
    }, []);

    this.formList = [...this.formList, ...newFormList];

  }

  setSiteName = (siteId: number, subjectId: string): string => {
    if (siteId === -1) 
      siteId  = this.subjectList.find((subject) => subject.id === subjectId)?.site.siteId || -1;
    return this.siteList.find((site) => site.id === siteId)?.name || '';
  }

  setSubjectName = (id: string): string => {
    return this.subjectList.find((subject) => subject.id === id)?.subjectId || '';
  }

  setVisitName = (id: string): string => {
    return this.visitList.find((visit) => visit.id === id)?.name || '';
  }

  setFormName = (id: number): string => {
    return this.formList.find((form) => form.formId === Number(id))?.formName || '';
  }

  setFieldName = (id: number): string => {
    return this.fieldList.find((field) => field.id === Number(id))?.label || '';
  }

  setUserName = (id: string): string => {
    return this.userList.find((user) => user.id === id)?.userName || '';
  }

  onChangePagination = (): void => {
    this.onPaginationChange.emit(this.page - 1);
    this.changeDetectorRef.detectChanges();
  }

  selectPage = (page: string): void => {
    this.page = Number(page) || 1;
    this.onPaginationChange.emit(this.page - 1);
    this.changeDetectorRef.detectChanges(); 
  }

  formatInput = (input: HTMLInputElement, pageLength: number) => {
    const FILTER_PAG_REGEX = /[^0-9]/g;
		input.value = input.value.replace(FILTER_PAG_REGEX, '');
    if (pageLength <= Number(input.value)) {
      input.value = String(pageLength);
    }
    (pageLength <= +input.value) ? input.value: pageLength
	}

  getKeys(row: any): string[] {
    return Object.keys(row);
  }

  onDownloadEvent(row: any) {
    this.onDownloadClick.emit(row);
  }

  onDeleteEvent(row: any) {
    this.onDeleteClick.emit(row);
  }

  onPreviewEvent(row: any) {
    this.onPreviewClick.emit(row);
  }
}