import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import type { ResponseField } from 'src/app/models/response/responseField';

@Injectable({
  providedIn: 'root',
})
export class FieldStoreService {
  private fieldData = new BehaviorSubject<ResponseField[]>([]);
  fieldData$ = this.fieldData.asObservable();

  constructor() {}

  getSiteSubjectData(): ResponseField[] {
    return this.fieldData.getValue();
  }

  setUserData(data: ResponseField[]): void {

    if (!data || data.length === 0) {
      this.fieldData.next([]);
      return;
    }

    this.fieldData.next(data);
  }
}