import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BLACK_COLOR, ERROR_ICON_COLOR } from 'src/app/constant/color-code';
import { NUMBER, OBJECT, QUERY_PRIORITY, STRING, UPDATING_DATA_REASON, componentTypeMap } from 'src/app/constant/constant';
import { CustomDateParserFormatter, MaskController } from 'src/app/constant/datepicker-parser';
import { DataEntryService } from 'src/app/services/data-entry.service';

@Component({
  selector: 'app-query-raising-popup',
  templateUrl: './query-raising-popup.component.html',
  styleUrls: ['./query-raising-popup.component.css'],
  providers:[CustomDateParserFormatter]
})
export class QueryRaisingPopupComponent implements OnInit {

  @Input() dataEntryData:any
  @Input() redirectForm:any
  @Input() fields:any
  @Input() dataTypeId:any
  @Input() fieldName:any
  @Input() rowId:any

  updateDataFormArray:any[] = []
  updateDataForm:any;

  isLocked:any;
  queryPriority:any = QUERY_PRIORITY
  dataEntryLoading:boolean = false
  isYesClicked : boolean = false

  @Output() modalYesClicked: EventEmitter<any> = new EventEmitter();
  @Output() modalNoClicked: EventEmitter<any> = new EventEmitter();

  //Reason Data
  reasonData:any = UPDATING_DATA_REASON

  mainDataTypeId:any

  errorIconColor:any = ERROR_ICON_COLOR
  blackColor:any = BLACK_COLOR

  constructor(private dataEntryAPI:DataEntryService,private toastr:ToastrService,
  private formBuilder:FormBuilder,private modalData:NgbActiveModal,private customDate:CustomDateParserFormatter,
  private maskController:MaskController) {
    this.updateDataForm = formBuilder.array([])
  }
  
  ngOnInit(): void {
    this.patchValueFromInput()
  }

  onSelectCommonReason(event:any){
    if((this.updateDataForm as FormArray).value){
      for (let index = 0; index < (this.updateDataForm as FormArray).value.length; index++) {
        let reasonData = (((this.updateDataForm as FormArray).get(index.toString()) as FormGroup).get("reason") as FormControl)
        reasonData.setValue(event.value)
        //Remove this condition because when selecting from common reson it should change all the reason (not only the null values).
        //if(reasonData.value == null){}
      }
    }
  }

  patchValueFromInput(){
    this.mainDataTypeId = this.dataTypeId
    if(this.dataEntryData.id != null){
      for (let index = 0; index < this.dataEntryData.fieldsData.length; index++) {
        if((this.dataEntryData.fieldsData[index].dataTypeId != undefined || this.dataEntryData.fieldsData[index].dataTypeId != null) && this.dataEntryData.fieldsData[index].dataTypeId == componentTypeMap[17].id){
          this.dataTypeId = this.dataEntryData.fieldsData[index].dataTypeId
        }
        
        let fieldData:any = {
          
          id:new FormControl(this.dataEntryData.fieldsData[index].id),
          fieldId:new FormControl(this.dataEntryData.fieldsData[index].fieldId),
          colHeading:new FormControl(this.dataEntryData.fieldsData[index].colHeading),
          fieldName:new FormControl(this.dataEntryData.fieldsData[index].fieldName),
          rowId:new FormControl(this.dataEntryData.fieldsData[index].rowId != null ? this.dataEntryData.fieldsData[index].rowId : -1),
          value:new FormControl(this.dataEntryData.fieldsData[index].value),
          reason:new FormControl(this.dataEntryData.fieldsData[index].reason),
          comment:new FormControl(this.dataEntryData.fieldsData[index].comment),
          oldValue:new FormControl(this.dataEntryData.fieldsData[index].oldValue),
          isReasonCapture:new FormControl(this.dataEntryData.fieldsData[index].isReasonCapture),
          dataTypeId:new FormControl(this.dataEntryData.fieldsData[index].dataTypeId)
        };
        (this.updateDataForm as FormArray).push(new FormGroup(fieldData))
      }
    }
  }

  get getQueryToBeRaised(){
    if(this.dataEntryData.fieldQueryDTOList){
      for (let index = 0; index < this.dataEntryData.fieldQueryDTOList.length; index++) {
        if(typeof(this.dataEntryData.fieldQueryDTOList[index].fieldValue) == OBJECT){
          this.dataEntryData.fieldQueryDTOList[index].fieldValue = this.getOptionNameFromId(this.dataEntryData.fieldQueryDTOList[index].fieldValue,this.dataEntryData.fieldQueryDTOList[index].multiRowId != undefined ? this.dataEntryData.fieldQueryDTOList[index].multiRowId : this.dataEntryData.fieldQueryDTOList[index].fieldId,this.dataEntryData.fieldQueryDTOList[index].fieldId)
        }
      }
    }
    return this.dataEntryData.fieldQueryDTOList
  }

  // Update multiple times becaues it is bind with the UI
  // get getUpdatingData(){
  //   if(this.dataEntryData.fieldsData != null){
  //     return this.dataEntryData.fieldsData
  //   }else{
  //     return []
  //   }
  // }
  
  checkIsReasonCaptureData(){
    if(this.dataEntryData.fieldsData != null){
      for (let index = 0; index < this.dataEntryData.fieldsData.length; index++) {
        if(this.dataEntryData.fieldsData[index].isReasonCapture == true){
          return true
        }
      }
    }
    return false
  }

  getOptionNameFromId(data:any,id:any,fieldId:any){
    let optionName:string = ''
    let timeData:string = ''
    let dateTimeStringData:string = "";
    if (typeof(data) === STRING || typeof(data) === NUMBER ) {
      return data
    }

    if(this.fields){
      for (let i = 0; i < this.fields.length; i++) {
        if(this.fields[i].id == id){
          if (this.fields[i].columnData && this.fields[i].columnData.length > 0 ) {
            this.fields[i].columnData.forEach((column: any) => {
              if (column.options.length > 0) {
                column.options.forEach((option: any) => {
                  if(column.dataTypeId == 7){
                    for(let obj in data){
                      if(option.id == obj){
                        optionName = option.name + ","
                      }
                    }
    
                  }else if(this.fields[i].dataTypeId == 8 || column.dataTypeId == 6){
                    for(let obj in data){
                      if(option.id == obj){
                        optionName += option.name + ","
                      }
                    }
                  }
                });
              }
            });
            return optionName.substring(0,optionName.length-1);
          }

          if(this.fields[i].options){            
            for (let j = 0; j < this.fields[i].options.length; j++) {
              if(this.fields[i].dataTypeId == 6 || this.fields[i].dataTypeId == 7){
                for(let obj in data){
                  if(this.fields[i].options[j].id == obj && data[obj] == true){
                    optionName += this.fields[i].options[j].name + ","
                  }
                }

              }else if(this.fields[i].dataTypeId == 8){
                for(let obj in data){
                  if(this.fields[i].options[j].id == obj && data[obj] == this.fields[i].options[j].name){
                    optionName += this.fields[i].options[j].name + ","
                  }
                }
              }
            }
            return optionName.substring(0,optionName.length-1);
          }else{
            if(this.fields[i].dataTypeId == 6 || this.fields[i].dataTypeId == 7 || this.fields[i].dataTypeId == 8){
              return ""
            }else if(this.fields[i].dataTypeId == 12){
              if(data && (data['year'] == undefined || data['year'] == null || data['year'] == '') && (data['month'] == undefined || data['month'] == null || data['month'] == '') && (data['day'] == undefined || data['day'] == null || data['day'] == '')){
                return ""
              }else{
                this.maskController.setMask(this.fields[i].formatting['dateFormat'])
                return this.customDate.format(data)
              }
            }else if(this.fields[i].dataTypeId == 13){
              if(data && (data['hour'] == null || data['hour'] == '') && (data['minute'] == null || data['minute'] == '') && (data['second'] == null || data['second'] == '')){
                return ""
              }else{
                timeData = this.generateTimeComponentString(data)
                // if(data['hour'] != null){
                //   timeData += data['hour'] + ":"
                // }
                // if(data['minute'] != null){
                //   timeData += data['minute'] + ":"
                // }
                // if(data['second'] != null){
                //   timeData += data['second']
                // }
                return timeData.endsWith(":") ? timeData.substring(0,timeData.length-1) : timeData;
              } 
            }else if(this.fields[i].dataTypeId == componentTypeMap[18].id){
              if(data && (data['year'] == null || data['year'] == '') && (data['month'] == null || data['month'] == '') && (data['day'] == null || data['day'] == '') && (data['hour'] == null || data['hour'] == '') && (data['minute'] == null || data['minute'] == '') && (data['second'] == null || data['second'] == '')){
                return ""
              }else{
                this.maskController.setMask(this.fields[i].formatting['dateFormat'])
                dateTimeStringData += this.customDate.format(data) + " "
                dateTimeStringData += this.generateTimeComponentString(data)
                // if(data['hour'] != null){
                //   dateTimeStringData += data['hour'] + ":"
                // }
                // if(data['minute'] != null){
                //   dateTimeStringData += data['minute'] + ":"
                // }
                // if(data['second'] != null){
                //   dateTimeStringData += data['second'] + " "
                // }
                // if(data['midDay'] != null){
                //   dateTimeStringData += data['midDay']
                // }
                return dateTimeStringData.endsWith(":") ? dateTimeStringData.substring(0,dateTimeStringData.length-1) : dateTimeStringData;
              } 
            }else{
              return data
            }
          }
        }else{
          if(this.dataTypeId == componentTypeMap[16].id && this.fields[i].id == fieldId){
            for (let j = 0; j < this.fields[i].columnData.length; j++) {
              if(this.fields[i].columnData[j].id == id){
                if(this.fields[i].columnData[j].options.length > 0){
                  for (let k = 0; k < this.fields[i].columnData[j].options.length; k++) {
                    if(this.fields[i].columnData[j].dataTypeId == 6 || this.fields[i].columnData[j].dataTypeId == 7){
                      for(let obj in data){
                        if(this.fields[i].columnData[j].options[k].id == obj && data[obj] == true){
                          optionName += this.fields[i].columnData[j].options[k].name + ","
                        }
                      }
                    }else if(this.fields[i].columnData[j].dataTypeId == 8){
                      for(let obj in data){
                        if(this.fields[i].columnData[j].options[k].id == obj && data[obj] == this.fields[i].columnData[j].options[k].name){
                          optionName += this.fields[i].columnData[j].options[k].name + ","
                        }
                      }
                    }
                  }
                  return optionName.substring(0,optionName.length-1);
                }else{
                  if(this.fields[i].columnData[j].dataTypeId == 6 || this.fields[i].columnData[j].dataTypeId == 7 || this.fields[i].columnData[j].dataTypeId == 8){
                    return ""
                  }else if(this.fields[i].columnData[j].dataTypeId == 12){
                    if(data && (data['year'] == undefined || data['year'] == null || data['year'] == '') && (data['month'] == undefined || data['month'] == null || data['month'] == '') && (data['day'] == undefined || data['day'] == null || data['day'] == '')){
                      return "";
                    }else{
                      this.maskController.setMask(this.fields[i].columnData[j].formatting['dateFormat'])
                      return this.customDate.format(data)
                    }
                  }else if(this.fields[i].columnData[j].dataTypeId == 13){
                    timeData = this.generateTimeComponentString(data)
                    // if(data && data['hour'] != null){
                    //   timeData += data['hour'] + ":"
                    // }
                    // if(data && data['minute'] != null){
                    //   timeData += data['minute'] + ":"
                    // }
                    // if(data && data['second'] != null){
                    //   timeData += data['second']
                    // }
                    return timeData.endsWith(":") ? timeData.substring(0,timeData.length-1) : timeData;
                  }else{
                    return data
                  }
                }
              }
            }
            
          }else if(this.dataTypeId == componentTypeMap[17].id && this.fields[i].id == fieldId){
            for (let row = 0; row < this.fields[i].cellInfo.length; row++) {
              for (let column = 0; column < this.fields[i].cellInfo[row].length; column++) {
                for (let cellIndex = 0; cellIndex < this.fields[i].cellInfo[row][column].length; cellIndex++) {
                  let cellData = this.fields[i].cellInfo[row][column][cellIndex]
                  if(cellData.id == id){
                    if(cellData.options && cellData.options.length > 0){
                      for (let k = 0; k < cellData.options.length; k++) {
                        if(cellData.dataTypeId == 6 || cellData.dataTypeId == 7){
                          for(let obj in data){
                            if(cellData.options[k].id == obj && data[obj] == cellData.options[k].name){
                              optionName += cellData.options[k].name + ","
                            }
                          }
                        }else if(cellData.dataTypeId == 8){
                          for(let obj in data){
                            if(cellData.options[k].id == obj && data[obj] == cellData.options[k].name){
                              optionName += cellData.options[k].name + ","
                            }
                          }
                        }
                      }
                      return optionName.substring(0,optionName.length-1);
                    }else{
                      if(cellData.dataTypeId == 6 || cellData.dataTypeId == 7 || cellData.dataTypeId == 8){
                        return ""
                      }else if(cellData.dataTypeId == 12){
                        this.maskController.setMask(cellData.formatting['dateFormat'])
                        return this.customDate.format(data)
                      }else if(cellData.dataTypeId == 13){
                        timeData = this.generateTimeComponentString(data)
                        // if(data && data['hour'] != null){
                        //   timeData += data['hour'] + ":"
                        // }
                        // if(data && data['minute'] != null){
                        //   timeData += data['minute'] + ":"
                        // }
                        // if(data && data['second'] != null){
                        //   timeData += data['second']
                        // }
                        return timeData.endsWith(":") ? timeData.substring(0,timeData.length-1) : timeData;
                      }else{
                        return data
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  
  closePopup(){
    this.modalData.close()
    //If any data want to emit into data-entry page then.
    // this.modalNoClicked.emit()
  }
  
  submitDataInApi(){
    if(this.dataEntryData.id == undefined || this.dataEntryData.id == null){
      this.dataEntryLoading = true
      this.isYesClicked = true
      if(this.rowId == -1){
        this.saveDataEntry(this.dataEntryData)
      }else{
        this.saveMultiRowDataEntry(this.dataEntryData)
      }
    }else{
      this.updateDataEntry(this.dataEntryData)
    }
  }

  saveDataEntry(value:any){
    this.dataEntryLoading = true
    this.dataEntryAPI.postData(value).subscribe(res=>{
      if(res.status != 0){
        this.toastr.error(res.message)
      }else{
        this.toastr.success(res.message)
        this.modalYesClicked.emit({redirectForm:this.redirectForm,isDataEntryUpdated:true});
        this.modalData.close()
      }
      this.dataEntryLoading = false
      this.isYesClicked = false
    },err => {
      this.dataEntryLoading = false
    })
  }

  updateDataEntry(value:any){
    value.fieldsData = this.updateDataForm.value
    if(value.fieldsData.length > 0){
      for (let i = 0; i < value.fieldsData.length; i++) {
        if(value.fieldsData[i].isReasonCapture == true){
          if(value.fieldsData[i].reason == null || value.fieldsData[i].reason == ''){
            this.toastr.warning("Reason is required")
            return
          }
        }
        // else if(value.fieldsData[i].comment == null || value.fieldsData[i].comment == ''){
        //   this.toastr.warning("Comment is required")
        //   return
        // }
      }
    }
    this.dataEntryLoading = true
    this.dataEntryAPI.updateDataEntryForm(value).subscribe(res=>{
      if(res.status != 0){
        this.toastr.error(res.message)
      }else{
        this.toastr.success(res.message)
        this.modalYesClicked.emit({redirectForm:this.redirectForm,isDataEntryUpdated:true});
        this.modalData.close()
      }
      this.dataEntryLoading = false
      this.isYesClicked = false
    },err => {
      this.dataEntryLoading = false
    })
  }

  saveMultiRowDataEntry(value:any){
    this.dataEntryLoading = true
    this.dataEntryAPI.postData(value).subscribe(res=>{
      if(res.status == 0){
        this.toastr.success(res.message)
        this.modalYesClicked.emit({redirectForm: res, isDataEntryUpdated:true});
      }else{
        this.toastr.error(res.message)
      }
      this.modalData.close()
      this.dataEntryLoading = false
      this.isYesClicked = false
    },err => {
      this.dataEntryLoading = false
    })
  }

  generateTimeComponentString(data:any){
    let timeString:string = ""
    if(data){
      if(data['hour']){
        timeString = timeString + data['hour']
      }
      if(data['hour'] && data['minute']){
        timeString = timeString + ":"
      }
      if(data['minute']){
        timeString = timeString + data['minute']
      }
      if(data['minute'] && data['second']){
        timeString = timeString + ":"
      }
      if(data['second']){
        timeString = timeString + data['second']
      }
      if(data['second'] && data['midDay']){
        timeString = timeString + " "
      }
      if(data['midDay']){
        timeString = timeString + data['midDay']
      }
    }
    return timeString
  }
}
