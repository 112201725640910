import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { distinctUntilChanged } from 'rxjs';
import { FormService } from 'src/app/services/form.service';
import { SiteService } from 'src/app/services/site.service';

@Component({
  selector: 'filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class FilterComponent implements OnInit {

  @Input() isShowSiteFilter: boolean = true;
  @Input() isShowSubjectFilter: boolean = true;
  @Input() isShowVisitFilter: boolean = false;
  @Input() isShowFormFilter: boolean = false;

  @Output() onSelectedFilterData: EventEmitter<any> = new EventEmitter<any>();

  subjectList: Array<any> = [];
  siteList: Array<any> = [];
  visitList: Array<any> = [];
  formList: Array<any> = [];
  filterForm!: FormGroup;
  subjectDropDownConfiguration!: IDropdownSettings;
  siteDropDownConfiguration!: IDropdownSettings;
  visitDropdownConfiguration!: IDropdownSettings;
  formDropdownConfiguration!: IDropdownSettings;
  allSiteDetails: any;
  allSubjectDetails: any;
  allVisitDetails: any;

  constructor(private changeDetectorRef: ChangeDetectorRef,
              private siteService: SiteService,
              private formBuilder: FormBuilder,
              private formService: FormService) {
    this.filterForm = this.formBuilder?.group({
      subject: new FormControl({}),
      site: new FormControl({}),
      visit: new FormControl({}),
      form: new FormControl({})
    })
  }

  ngOnInit(): void {
    this.setDropDownConfiguration();
    this.setSiteAndSubjectData();
    this.manipulateSubjectList();
    this.setVisitList('');
    this.detectChanges();
  }

  setDropDownConfiguration = (): void => {
    this.subjectDropDownConfiguration = this.getDropDownConfiguration('id', 'subjectId', false, true);
    this.siteDropDownConfiguration = this.getDropDownConfiguration('id', 'name', false, true);
    this.visitDropdownConfiguration = this.getDropDownConfiguration('id', 'name', false, true);    
    this.formDropdownConfiguration = this.getDropDownConfiguration('formId', 'formName', false, true);    
  }

  setSiteAndSubjectData = (): void => {
    this.siteService?.getAllSiteSubjectData().subscribe({
      next: (res: any) => {
        this.allSiteDetails = res.responseObject?.sites || {};
        this.siteList = Object.keys(this.allSiteDetails)?.map(key => {
          this.subjectList = [...this.subjectList, ...this.allSiteDetails[key]?.subjects];
          this.allSubjectDetails = this.subjectList;
          return {
            id: key,
            name: this.allSiteDetails[key]?.name || ''
          }
        });
        this.detectChanges();
      },
      error: (error) => {
        this.siteList = [];
        this.subjectList = [];
        this.detectChanges();
      }
    });
  }

  getDropDownConfiguration = (idField: string, textField: string, isSingleSelection: boolean, allowSearchFilter: boolean): any => {
    return {
      singleSelection: isSingleSelection,
      idField: idField,
      textField: textField,
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect',
      itemsShowLimit: 2,
      allowSearchFilter: allowSearchFilter,
    }
  }

  setVisitList = (selectedSubject:any): void => {
    this.visitList = []
    this.formService.getVisitFormData(selectedSubject).subscribe({
      next: res => { 
        this.allVisitDetails = res?.responseObject;
        this.visitList = this.getVisitList(res?.responseObject?.['visit']);
        this.manipulateFormList();
        this.detectChanges();
      },
      error: error => {
        this.allVisitDetails = [];
        this.visitList = [];
        this.formList = [];
        this.detectChanges();
      }
    });
  }

  getVisitList(selectedVisits: any): Array<any> {
    const visitList: Array<any> = [];
    let formList: Array<any> = [];
    Object.keys(selectedVisits).map((visitId) => {
      visitList?.push({ id: visitId, name: selectedVisits?.[visitId]?.name || '' });
      selectedVisits[visitId]?.forms.map((formDetail: any)=>{
        formList.push(formDetail);
      })
    });
    this.formList = formList;
    return visitList;
  }

  resetDropDownSelection = (formControlName: string): void => {
    this.filterForm?.get(formControlName)?.reset();
  }

  manipulateSubjectList() {
    this.filterForm.get('site')?.valueChanges.pipe(distinctUntilChanged()).subscribe((data) => {      
      this.updateSubjectList(data);
    })
  }

  updateSubjectList = (data: any): void => {
    if (data?.length == 0) {
      this.subjectList = this.allSubjectDetails;
      return;
    }
    this.subjectList = [];
    this.subjectList = data?.reduce((previousArray: any[], key: any) => [...previousArray, ...this.allSiteDetails[key.id]?.subjects || []], []);
  }

  manipulateFormList = (): void => {
    this.filterForm?.get('visit')?.valueChanges?.pipe(distinctUntilChanged())?.subscribe({
      next: (selectedVisit) => {
        selectedVisit = selectedVisit?.length ? selectedVisit : Object?.keys(this.allVisitDetails?.visit || {})?.map(visitId => {
          return {
            id: visitId,
            name: this.allVisitDetails?.visit[visitId]?.name
          }
        });
        this.formList = this.getFormList(selectedVisit);
        this.detectChanges();
      },
      error: (error) => {
        this.formList = [];
        this.detectChanges();
      }
    });
  }

  getFormList = (selectedVisits: Array<any>): Array<any> => {    
    const formList: Array<any> = [];
    if(selectedVisits){
      selectedVisits.forEach((visitDetail) => {
        formList.push(...this.allVisitDetails?.['visit']?.[visitDetail?.id]?.forms);
      });      
    }
    return formList;
  }

  onApplyFilter = (): void => {
    const eventData = {
      subjectIds: Array.isArray(this.filterForm?.get('subject')?.value) &&this.filterForm?.get('subject')?.value?.map((data: any) => data?.id)?.join(',') || [],
      siteIds: Array.isArray(this.filterForm?.get('site')?.value) && this.filterForm?.get('site')?.value?.map((data: any) => data?.id)?.join(',') || [],
      visitIds: Array.isArray(this.filterForm?.get('visit')?.value) && this.filterForm?.get('visit')?.value?.map((data: any) => data?.id)?.join(',') || [],
      formIds: Array.isArray(this.filterForm?.get('form')?.value) && this.filterForm?.get('form')?.value?.map((data: any) => data?.formId)?.join(',') || []
    }
    this.onSelectedFilterData?.emit(eventData);
  }

  onClearFilter = (): void => {
    this.resetDropDownSelection('subject');
    this.resetDropDownSelection('site');
    this.resetDropDownSelection('visit');
    this.resetDropDownSelection('form');
    this.onSelectedFilterData?.emit({
      subjectIds: [],
      siteIds: [],
      visitIds: [],
      formIds: []
    });
  }

  detectChanges = (): void => {
    try {
      this.changeDetectorRef?.detectChanges();
    } catch(error) {
    }
  }
}
