<form [formGroup]="filterForm"> 
    <div class="row">
      <div class="col">
        <div class="form-floating mb-2">
          <ng-multiselect-dropdown class="dropdown-list" 
                                   placeholder="Site" 
                                   formControlName="site" 
                                   [settings]="siteDropDownConfiguration"
                                   [data]="siteList"
                                   (onDeSelect)="resetDropDownSelection('subject')" 
                                   (onDeSelectAll)="resetDropDownSelection('subject')" 
                                   (onSelect)="resetDropDownSelection('subject')" 
                                   (onSelectAll)="resetDropDownSelection('subject')">
          </ng-multiselect-dropdown>
        </div>
      </div>

      <div class="col">
        <div class="form-floating mb-2">
          <ng-multiselect-dropdown class="dropdown-list" 
                                  placeholder="Subject" 
                                  formControlName="subject"
                                  [settings]="subjectDropDownConfiguration"
                                  [data]="subjectList">
          </ng-multiselect-dropdown>
        </div>
      </div>

      <div class="col">
        <div class="form-floating mb-2">
          <ng-multiselect-dropdown class="dropdown-list"
                                   formControlName="visit"
                                   placeholder="Visit"
                                   [settings]="visitDropdownConfiguration"
                                   [data]="visitList"
                                   (onDeSelect)="resetDropDownSelection('form')"
                                   (onDeSelectAll)="resetDropDownSelection('form')"
                                   (onSelect)="resetDropDownSelection('form')" 
                                   (onSelectAll)="resetDropDownSelection('form')">
          </ng-multiselect-dropdown>
        </div>
      </div>
      <div class="col">
        <div class="form-floating mb-2">
          <ng-multiselect-dropdown class="dropdown-list"
                                   formControlName="form"
                                   placeholder="Form"
                                   [settings]="formDropdownConfiguration"
                                   [data]="formList">
          </ng-multiselect-dropdown>
        </div>
      </div>

      <div class="col text-center">
        <button class="btn btn-outline-success"
                (click)="onApplyFilter()">
          <b>Search</b>
        </button>
      </div>
      <div class="col text-center">
        <button class="btn btn-outline-danger"
                (click)="onClearFilter()">
          <b>Clear Filter</b>
        </button>
      </div>
    </div>
  </form>