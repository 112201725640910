  <div class="container-fluid">
  <div class="jumbotron jumbotron-fluid">
    <div class="row">
      <div class="col-sm col-md-9 col-lg-9 col-xl-10">
        <p class="heading">{{title}}</p>
      </div>
      <div class="col-sm col-md-3 col-lg-3 col-xl-2 mt-2 mb-1">
        <a [routerLinkActive]="['router-link-active']" [routerLink]="['/layout/custom-report-design']">
          <button class="btn btn-outline-primary form-control" type="button">
            <b>Add New</b>
          </button>
        </a>
      </div>
    </div>
    <div class="row" [formGroup]="filterFormGroup">
      <div class="col-sm col-md-6 col-lg-6 col-xl-6 mb-1">
        <input type="text" class="form-control custom-pages-input" placeholder="Report Name"
          formControlName="reportName">
      </div>
      <div class="col-sm col-md col-lg col-xl mb-1">
        <button type="button" title="Search" class="btn btn-outline-primary form-control" (click)="searchByName()">
          <b>Search</b>
        </button>
      </div>
      <div class="col-sm col-md col-lg col-xl mb-1">
        <button type="button" title="Search" class="btn btn-outline-danger form-control" (click)="clearFilter()">
          <b>Clear Filter</b>
        </button>
      </div>
    </div>
  </div>
  <hr>
  <div>
    <div *ngIf="tableData.length; else noData">
      <table class="table table-hover table-light">
        <thead class="bg-light sticky-top">
          <tr>
            <th scope="col">Sr. No.</th>
            <th scope="col">Report Name</th>
            <!-- <th scope="col">Show To</th> -->
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of tableData; let i = index;">
            <td scope="row">{{serialNumber + i}}</td>
            <td>{{row.name}}</td>
            <!-- <td>{{row.showTo}}</td> -->
            <td>
              <button class="control-buttons" title="Preview" (click)="gotoPreview(row)">
                <span class="previewIcon"></span>
              </button> &nbsp;
              <button class="control-buttons" title="Update" (click)="gotoEdit(row)">
                <span class="updateIcon"></span>
              </button> &nbsp;
              <button class="control-buttons" title="Delete" (click)="onDelete(row)">
                <span class="deleteIcon"></span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="tableFooterHr"></div>
      <ngb-pagination [collectionSize]="totalPages" [(page)]="page" [boundaryLinks]="true" [rotate]="true"
        [ellipses]="false" (pageChange)="onChangePagination()">
        <ng-template ngbPaginationPages let-page let-pages="pages">
          <li class="ngb-custom-pages-item" *ngIf="pages.length > 0">
            <div class="mb-3 d-flex flex-nowrap px-2">
              <label id="paginationInputLabel" for="paginationInput" class="col-form-label me-2 ms-1">Page</label>
              <input #i type="text" inputmode="numeric" pattern="[0-9]*" class="form-control custom-pages-input"
                id="paginationInput" [value]="page" (keyup.enter)="selectPage(i.value)" (blur)="selectPage(i.value)"
                (input)="formatInput($any($event).target)" aria-labelledby="paginationInputLabel paginationDescription"
                style="width: 2.3em" />
              <span id="paginationDescription" class="col-form-label text-nowrap px-2"> of {{pages.length}}</span>
            </div>
          </li>
        </ng-template>
      </ngb-pagination>
    </div>
  </div>
  <ng-template #noData>
    <h5>
      No Data Available! &nbsp;
    </h5>
  </ng-template>
</div>