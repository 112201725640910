import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Visits } from 'src/app/models/visits';
import { baseURL, VERSION } from 'src/app/constant/constant';

@Injectable({
  providedIn: 'root'
})
export class VisitsService {

  visitList$$: any = new BehaviorSubject(null);
  constructor(private http:HttpClient) { }
  
  getAllVisits():Observable<any>{
    return this.http.get(`${baseURL}visits/${VERSION}/list`);
  }

  getVisitListByStudyId(options: any):Observable<any>{
    return this.http.get(`${baseURL}visits/${VERSION}/list/study/${options?.params?.studyId}`);
  }
  
  addVisit(data:Visits):Observable<any>{
    return this.http.post(`${baseURL}visits/${VERSION}`,data);
  }
  
  updateVisit(id:string, data:Visits):Observable<any>{
    return this.http.put(`${baseURL}visits/${VERSION}`,data);
  }
  
  deleteVisit(id:string):Observable<any>{
    return this.http.delete(`${baseURL}visits/${VERSION}?id=${id}`);
  }

  setOrderId(data:any):Observable<any>{
    return this.http.put(`${baseURL}visits/${VERSION}/orderId`,data);
  }

  getVisitsBySubjectId(subjectId?:any):Observable<any>{
    return this.http.get(`${baseURL}visits/${VERSION}/idName?subjectId=${subjectId}`);
  }

  getVisitsByStudyId(studyId?:any):Observable<any>{
    return this.http.get(`${baseURL}visits/${VERSION}/studyId/${studyId}/idName`);
  }

  getVisitsByStudyIdAndSubjectId(studyId:any,subjectId?:any):Observable<any>{
    return this.http.get(`${baseURL}visits/${VERSION}/studyId/${studyId}/idName?subjectId=${subjectId}`);
  }

  visitExport(data:any):Observable<any>{
    return this.http.post(`${baseURL}export/visit`,data)
  }

  getVisitDateTrackerData(pageNo: number, pageSize: number, siteIds?: any[], subjectIds?: any[], visitIds?: any[]): Observable<any> {
    let url: string = `${baseURL}visitTracker/${VERSION}/list?pageNo=${pageNo}&pageSize=${pageSize}`
    if (siteIds && siteIds.length > 0) {
      url = url.concat(`&siteIds=${siteIds}`)
    }
    if (subjectIds && subjectIds.length > 0) {
      url = url.concat(`&subjectIds=${subjectIds}`)
    }
    if (visitIds && visitIds.length > 0) {
      url = url.concat(`&visitIds=${visitIds}`)
    }
     return this.http.get(url);
  }
}
