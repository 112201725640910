import { NgModule } from "@angular/core";
import { FilterComponent } from "./filter.component";
import { CommonModule } from "@angular/common";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { ReactiveFormsModule } from "@angular/forms";

@NgModule({
    declarations: [
        FilterComponent
    ],
    imports: [
        CommonModule,
        NgMultiSelectDropDownModule,
        ReactiveFormsModule
    ],
    exports: [
        FilterComponent
    ]
})
export class FilterModule {
}