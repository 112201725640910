import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseURL, VERSION } from '../constant/constant';
import { Notification, NotificationConfiguration } from '../constant/export-types';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private http: HttpClient) { }

  saveNotificationData(data: any): Observable<{status: number;message: string;responseObject: NotificationConfiguration;}>{
    return this.http.post<{status:number;message:string;responseObject:NotificationConfiguration}>(`${baseURL}notification/${VERSION}`, data);
  }

  getNotificationList(studyId: any, pageNo: any, pageSize: any){
    return this.http.get<{status:number;message:string;responseObject:Notification[];}>(`${baseURL}notification/${VERSION}/list?studyId=${studyId}&pageNo=${pageNo}&pageSize=${pageSize}`);
  }

  getNotificationById(id: number){
    return this.http.get<{status:number;message:string;responseObject:NotificationConfiguration;}>(`${baseURL}notification/${VERSION}?id=${id}`);
  }

  // Update a configuration
  updateConfiguration(data: any): Observable<{status:number;message:string;responseObject:NotificationConfiguration;}>{
    return this.http.put<{status:number;message:string;responseObject:NotificationConfiguration}>(`${baseURL}notification/${VERSION}`,data);
  }

  // Delete a configuration
  deleteConfiguration(id: number): Observable<{status:number;message:string;}>{
    return this.http.delete<{status:number;message:string;}>(`${baseURL}notification/${VERSION}?id=${id}`);
  }

}
