export const SUCCESS_ICON_COLOR = "green"
export const PARTIAL_SUCCESS_ICON_COLOR = "gold"
export const ERROR_ICON_COLOR = "red"
export const BACKGROUND_ICON_COLOR = "white"

export const QUERY_RAISED_ICON_COLOR = "red"
export const QUERY_RESPONDED_ICON_COLOR = "gold"
export const QUERY_CLOSE_ICON_COLOR = "rgb(2, 123, 8)"
export const NO_QUERY_ICON_COLOR = "lightgrey"

export const NOTE_COLOR = "blue"

export const BACKGROUND_COLOR = "white"
export const BLACK_COLOR = "black"

export const QUERY_LOW_PRIORITY_ICON_COLOR = "green"
export const QUERY_MEDIUM_PRIORITY_ICON_COLOR = "gold"
export const QUERY_HIGH_PRIORITY_ICON_COLOR = "red"

//Dashboard Graph Color
export const DASHBOARD_RED = "rgb(255, 99, 132)"
export const DASHBOARD_RED_OPACITY = "rgb(255, 99, 132, 0.5)"

export const DASHBOARD_BLUE = "rgb(54, 162, 235)"
export const DASHBOARD_BLUE_OPACITY = "rgb(54, 162, 235, 0.5)"

export const DASHBOARD_YELLOW = "rgb(255, 205, 86)"
export const DASHBOARD_YELLOW_OPACITY = "rgb(255, 205, 86, 0.5)"

export const DASHBOARD_GREEN = "rgb(60, 179, 113)"
export const DASHBOARD_GREEN_OPACITY = "rgb(60, 179, 113, 0.5)"

export const DASHBOARD_ORANGE = "rgb(230, 126, 34)"
export const DASHBOARD_ORANGE_OPACITY = "rgb(230, 126, 34, 0.5)"