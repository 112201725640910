import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseURL, VERSION } from '../constant/constant';

@Injectable({
  providedIn: 'root'
})
export class EmailTemplateService {

  constructor( private http: HttpClient ) { }

   getEmailTemplate(){
      return this.http.get(`${baseURL}emailTemplate/${VERSION}`)
    }

}
