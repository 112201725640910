import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import type { ResponseSiteSubject, ResponseSubject } from 'src/app/models/response/responseSiteSubject';

@Injectable({
  providedIn: 'root',
})
export class SiteSubjectStoreService {
  private siteSubjectData = new BehaviorSubject<Map<number, ResponseSiteSubject>>(new Map());
  siteSubjects$ = this.siteSubjectData.asObservable();

  constructor() {}

  getSiteSubjectData(): Map<number, ResponseSiteSubject> {
    return this.siteSubjectData.getValue();
  }

  // Allow manual update of data
  setSiteSubjectData(data: Map<number, ResponseSiteSubject> | null): void {

    if (!data || data.size === 0) {
      this.siteSubjectData.next(new Map());
      return;
    }

    this.siteSubjectData.next(data);
  }
}
