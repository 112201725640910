<!-- Impact Key -->
<div *ngIf="validationAndLogMapping" [formGroup]="validationAndLogMapping">
    <div formArrayName="rule">
        <div class="row" [formGroupName]="ruleLength">
            <!-- Impact Design -->
            <div class="row">
                <div class="col-2 mt-3">
                    <h5>Then</h5>
                </div>
                <!-- Select Validation Action Started -->
                <div class="col-4">
                    <div class="form-floating mb-3">
                        <select class="form-select" id="action" formControlName="action">
                            <option [value]="null" selected>--Select--</option>
                            <option *ngFor="let ac of validationAction" [value]="ac.id"><b>{{ac.name}}</b></option>
                        </select>
                        <label for="action"><b>Select Action</b></label>
                    </div>
                </div>
                <!-- Select Validation Action Ended -->
                <!-- Enter Message TextBox Started -->
                <!-- <div class="col" *ngIf="getSelectedRuleKeyValue('action',ruleLength) != undefined">
                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" formControlName="message" id="message" name="message" placeholder="Enter Message">
                        <label for="message">Message</label>
                    </div>
                </div> -->
                <!-- Enter Message TextBox Ended -->
                <div class="row" *ngIf="getSelectedRuleKeyValue('action',ruleLength) != undefined">
                    <!-- Impact Key Started -->
                    <div formArrayName="impact">
                        <div *ngFor="let impact of getConditionOrImpactArrayRule('impact',ruleLength).controls;let j=index">
                            <div [formGroupName]="j">
                                <div class="row">
                                    <input type="hidden" name="id" formControlName="id">
                                    <!-- Select Validation Form Type SelectionBox Started -->
                                    <div class="col">
                                        <div class="form-floating mb-3">
                                            <select class="form-select" id="validationFormType" formControlName="validationFormType" (change)="onValidationFormTypeSelect($event.target,'impact',ruleLength,j)">
                                                <option [value]="null" selected>--Select--</option>
                                                <option *ngFor="let item of validationFormTypeData" [value]="item.id"><b>{{item.name}}</b></option>
                                            </select>
                                            <label for="validationFormType"><b>Form-Type</b></label>
                                        </div>
                                    </div>
                                    <!-- Select Validation Form Type SelectionBox Ended -->
                                    <!-- Select Visit SelectionBox Started -->
                                    <div class="col" *ngIf="getSelectedKeyValueForConditionAndImpact('impact','validationFormType',ruleLength,j)?.value != null && getSelectedKeyValueForConditionAndImpact('impact','validationFormType',ruleLength,j)?.value == 0">
                                        <div class="form-floating mb-3">
                                            <select class="form-select" id="visitId" formControlName="visit" (change)="onVisitSelect($event.target,'impact',ruleLength,j)">
                                                <option [value]="null" selected>--Select--</option>
                                                <option *ngFor="let item of visitListData" [value]="item.id"><b>{{item.name}}</b></option>
                                            </select>
                                            <label for="visitId"><b>Visit</b></label>
                                        </div>
                                    </div>
                                    <!-- Select Visit SelectionBox Ended -->
                                    <!-- Select Form SelectionBox Started -->
                                    <div class="col" *ngIf="getSelectedKeyValueForConditionAndImpact('impact','validationFormType',ruleLength,j)?.value != null && getSelectedKeyValueForConditionAndImpact('impact','visit',ruleLength,j)?.value != undefined">
                                        <div class="form-floating mb-3">
                                            <select class="form-select" id="formSelect" formControlName="form" (change)="onFormSelect($event.target,'impact',ruleLength,j)">
                                                <option [value]="null" selected>--Select--</option>
                                                <option *ngFor="let item of getSelectedKeyValueForConditionAndImpact('impact','uiFormSelect',ruleLength,j)?.value" [value]="item.formId"><b>{{item.formName}}</b></option>
                                            </select>
                                            <label for="formSelect"><b>Form</b></label>
                                        </div>
                                    </div>
                                    <!-- Select Form SelectionBox Ended -->
                                    <!-- MultiSelect Fields SelectionBox Started -->
                                    <div class="col mt-2" *ngIf="getSelectedKeyValueForConditionAndImpact('impact','validationFormType',ruleLength,j)?.value != null && getSelectedKeyValueForConditionAndImpact('impact','form',ruleLength,j)?.value != undefined">
                                        <ng-multiselect-dropdown class="dropdown-list" placeholder="Select Fields" formControlName="fields" [settings]="fieldsDropDownSettings" [data]="getSelectedKeyValueForConditionAndImpact('impact','uiFieldSelect',ruleLength,j)?.value" (onSelect)="onFieldSelect($event,'impact',ruleLength,j)" [disabled]="fetchedDataTypeId == 17 || fetchedDataTypeId == 18">
                                        </ng-multiselect-dropdown>
                                    </div>
                                    <!-- MultiSelect Fields SelectionBox Ended -->
                                    <!-- MultiSelect Column SelectionBox Started -->
                                    <div class="col mt-2" *ngIf="getSelectedKeyValueForConditionAndImpact('impact','validationFormType',ruleLength,j)?.value != null && getSelectedKeyValueForConditionAndImpact('impact','uiColumnSelect',ruleLength,j)?.value != null && getSelectedKeyValueForConditionAndImpact('impact','fields',ruleLength,j)?.value != undefined && fetchedDataTypeId == 17">
                                        <div class="form-floating mb-3">
                                            <ng-multiselect-dropdown class="dropdown-list" placeholder="Select Columns" formControlName="multiRowIds"  [settings]="columnsDropDownSettings" [data]="getSelectedKeyValueForConditionAndImpact('impact','uiColumnSelect',ruleLength,j)?.value">
                                            </ng-multiselect-dropdown>
                                        </div>
                                    </div>
                                    <!-- MultiSelect Column SelectionBox Ended -->
                                    <!-- MultiSelect Column Heading SelectionBox Started -->
                                    <div class="col mt-2" *ngIf="getSelectedKeyValueForConditionAndImpact('impact','validationFormType',ruleLength,j)?.value != null && getSelectedKeyValueForConditionAndImpact('impact','uiColumnSelect',ruleLength,j)?.value != null && getSelectedKeyValueForConditionAndImpact('impact','fields',ruleLength,j)?.value != undefined && fetchedDataTypeId == 18">
                                        <div class="form-floating mb-3">
                                            <ng-multiselect-dropdown class="dropdown-list" placeholder="Select Columns Headings" formControlName="multiRowIds" [settings]="columnsDropDownSettings" [data]="getSelectedKeyValueForConditionAndImpact('impact','uiColumnSelect',ruleLength,j)?.value">
                                            </ng-multiselect-dropdown>
                                        </div>
                                    </div>
                                    <!-- MultiSelect Column Heading SelectionBox Ended -->
                                    <!-- Add button for New Impact Started -->
                                    <div class="col-1 mt-3" *ngIf="getSelectedKeyValueForConditionAndImpact('impact','validationFormType',ruleLength,j)?.value != null && getSelectedRuleKeyValue('action',ruleLength) != 3">
                                        <button class="transparent-button" title="Add Impact" (click)="addNewImpact(ruleLength,j+1)" *ngIf="getSelectedKeyValueForConditionAndImpact('impact','fields',ruleLength,j)?.value != {}">
                                          <i class="fa fa-plus" aria-hidden="true"></i>
                                        </button> &nbsp;
                                        <button class="transparent-button" title="Remove Impact" (click)="removeImpact(ruleLength,j)">
                                          <i class="fa fa-times" aria-hidden="true"></i>
                                        </button>
                                      </div>
                                    <!-- Add Button for New Impact Ended -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Impact Key Ended -->
                </div>
                <div class="row">
                    <!-- Enter Message TextBox Started -->
                    <div class="col" *ngIf="getSelectedRuleKeyValue('action',ruleLength) != undefined">
                        <div class="form-floating mb-3">
                            <textarea type="text" class="form-control textAreaSizing" formControlName="message" id="message" name="message" placeholder="Enter Message"></textarea>
                            <!-- <input type="text" class="form-control" formControlName="message" id="message" name="message" placeholder="Enter Message"> -->
                            <label for="message">Message</label>
                        </div>
                    </div>
                    <!-- Enter Message TextBox Ended -->
                </div>
            </div>
        </div>
    </div>
</div>