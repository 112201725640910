<div class="table-responsive" *ngIf="idRowData?.length !== 0; else noDataTemplate;">
  <div class="staticHeight">
    <table class="table table-hover table-light">
      <thead class="thead-light">
        <tr>
           <th *ngFor="let columnName of columnList">{{columnName}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of idRowData">
          <td *ngFor="let key of getKeys(row)">

            <ng-container *ngIf="key !== 'action'; else actionButtons">
              {{ key === 'siteId' ? setSiteName(row['siteId'], row['subjectId']) : '' }}
              {{ key === 'subjectId' ? setSubjectName(row[key]) : '' }}
              {{ key === 'visitId' ? setVisitName(row['visitId']) : '' }}
              {{ key === 'formId' ? setFormName(row[key]) : '' }} 
              {{key === 'fieldId' ? setFieldName(row[key]): ''}}
              {{key === 'fileName' ?  row[key]: ''}}
              {{key === 'markedBy'? setUserName(row[key]): ''}}
              {{key === 'markedOn'? setDateByDateFormat(row[key]): ''}}
            </ng-container>
            <ng-template #actionButtons>
              <ng-container *ngIf="isShowAction">
                <button *ngIf="isShowPreview" class="control-buttons" title="View"
                  (click) = "onPreviewEvent(row)">
                  <span class="previewIcon"></span>
                </button>
                <button *ngIf="isShowDownload" class="control-buttons" title="Download File" (click) = "onDownloadEvent(row)">
                  <span class="fa fa-download" style="color: blue; cursor: pointer;"></span>
                </button>
                <button *ngIf="isShowDelete && roles === crc" class="control-buttons" title="Delete File" (click) = "onDeleteEvent(row)">
                  <span class="deleteIcon"></span>
                </button>
              </ng-container>
            </ng-template>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="tableFooterHr"></div>
    <ngb-pagination *ngIf="isPagination"
                  [collectionSize]="totalPages" 
                  [(page)]="page" 
                  [boundaryLinks]="true" 
                  [rotate]="true" 
                  [ellipses]="false" 
                  (pageChange)="onChangePagination()">
      <ng-template ngbPaginationPages 
                   let-page 
                   let-pages="pages">
        <li class="ngb-custom-pages-item" 
            *ngIf="pages.length > 0">
          <div class="mb-3 d-flex flex-nowrap px-2">
            <label id="paginationInputLabel" 
                   for="paginationInput" 
                   class="col-form-label me-2 ms-1">
                Page
            </label>
            <input #i
                   type="text"
                   inputmode="numeric"
                   pattern="[0-9]*"
                   class="form-control custom-pages-input"
                   id="paginationInput"
                   [value]="page"
                   (keyup.enter)="selectPage(i.value)"
                   (blur)="selectPage(i.value)"
                   (input)="formatInput($any($event).target, pages.length)"
                   aria-labelledby="paginationInputLabel paginationDescription"
                   style="width: 2.5rem"/>
            <span id="paginationDescription" 
                  class="col-form-label text-nowrap px-2"> 
                  of {{pages.length}}
            </span>
          </div>
        </li>
      </ng-template>
    </ngb-pagination>
  </div>
</div>
<ng-template #noDataTemplate >
  <h5 *ngIf="!isApiRunning">No Data Available!</h5>
  <div *ngIf="isApiRunning" class="text-center"> 
    <span class="spinner-border text-center"></span>
  </div>
</ng-template>