import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseURL , VERSION } from 'src/app/constant/constant';

@Injectable({
  providedIn: 'root'
})
export class DataEntryService {
  constructor(private http:HttpClient) { }

  postData(data:any):Observable<any>{
    return this.http.post(`${baseURL}dataEntries/${VERSION}`,data);
  }
  
  formStatus(sites:any,subjects:any,visits:any,forms:any,pageNo:any,pageSize:any):Observable<any>{
    return this.http.get(`${baseURL}dataEntries/${VERSION}/crc/status?sites=${sites}&subjects=${subjects}&visits=${visits}&forms=${forms}&pageNo=${pageNo}&pageSize=${pageSize}`);
  }

  getDataEntry(formId:any,visitId:any,subjectId:any):Observable<any>{
    return this.http.get(`${baseURL}dataEntries/${VERSION}?formId=${formId}&visitId=${visitId}&subjectId=${subjectId}`);
  }

  updateDataEntryForm(data:any):Observable<any>{
    return this.http.put(`${baseURL}dataEntries/${VERSION}/fields/update`,data)
  }

  insertDataEntryForm(data:any):Observable<any>{
    return this.http.post(`${baseURL}dataEntries/${VERSION}/multi/insert`,data);
  }

  //Multi Row API's
  saveMultiRowDataEntryForm(data:any):Observable<any>{
    return this.http.post(`${baseURL}dataEntries/${VERSION}/multirow/create`,data);
  }

  updateMultiRowDataEntryForm(data:any):Observable<any>{
    return this.http.put(`${baseURL}dataEntries/${VERSION}/multirow/update`,data);
  }

  deleteMultiRowDataEntryForm(visitId:any,formId:any,subjectId:any,rowId:any,fieldId:any,reason:any):Observable<any>{
    return this.http.delete(`${baseURL}dataEntries/${VERSION}/multirow/delete?visitId=${visitId}&formId=${formId}&subjectId=${subjectId}&rowId=${rowId}&fieldId=${fieldId}&reason=${reason}`);
  }
  
  getMultiRowDataEntryForm(visitId:any,formId:any,subjectId:any,fieldId:any,pageNo:number,pageSize:number):Observable<any>{
    return this.http.get(`${baseURL}dataEntries/${VERSION}/multirow/get/data?visitId=${visitId}&formId=${formId}&subjectId=${subjectId}&fieldId=${fieldId}&pageNo=${pageNo}&pageSize=${pageSize}`);
  }

  saveUnScheduleVisitData(subjectId:any,data:any):Observable<any>{
    return this.http.post(`${baseURL}dataEntries/visits/${VERSION}/subject/${subjectId}`,data);
  }
}
