<!-- <div class="container d-flex justify-content-center">
  <div class="row">
    <div class="jumbotron jumbotron-fluid">
      <div class="col">
        <form *ngIf="formValue" [formGroup]="formValue" novalidate>
          <div class="card mt-5">
            <div class="card-header">
              <div class="display-5">
                Forgot Password
              </div>
            </div>
            <div class="card-body">
              <div>
                <label><b>To resend your password, provide<br> the registered email-id.</b></label>
              </div>
              <br>
              <div class="form-floating mb-3">
                <input type="text" class="form-control" id="name" formControlName="email" placeholder="Enter Email" autocomplete="off">
                <label for="forName">Email</label>
              </div>
            </div> -->
            <!-- Submit Button -->
            <!-- <div class="card-footer text-center"> -->
              <!-- [routerLink]="['/layout/dashboard']" -->
              <!-- <button type="submit" class="btn btn-outline-primary" (click)="resetPassword()">
                Reset-Password <span class="spinner-border spinner-border-sm" *ngIf="loading" role="status" aria-hidden="true"></span>
              </button> &nbsp;&nbsp; -->
              <!-- <button type="submit" class="btn btn-outline-primary" (click)="resendPassword()">
                <b>
                  Resend Password <span class="spinner-border spinner-border-sm" *ngIf="loading" role="status" aria-hidden="true"></span>
                </b>
              </button> &nbsp;
              <button type="submit" class="btn btn-outline-danger" (click)="onCancelClicked()">
                <b>
                  Cancel
                </b>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div> -->

<!-- New Forgot Password Page -->
<!-- /* Try New LoginPage CSS */ -->
<section class="vh-100" style="background-color: #385b9b;">
  <div class="container py-5 h-100">
    <div class="row MainDiv h-100">
      <!-- Login Pannel Size According To Resolution -->
      <div class="col-sm col-md-10 col-lg-9 col-xl-7 animationLogin">
        <div class="card" style="border-radius: 1rem;">
          <div class="row g-0">
            <!-- Login Pannel Img -->
            <div class="col-sm col-md col-lg col-xl d-none d-md-block">
              <img src="../../../assets/Images/loginpage.webp" alt="login form" class="img-fluid ColImg"/>
            </div>
            <!-- Login Pannel -->
            <div class="col-sm col-md col-lg col-xl LoginPannel">
              <!-- Padding Spacing In Logout PANNEL -->
              <div class="card-body p-2 p-md-3 p-lg-4 p-xl-5">
                <form *ngIf="formValue" [formGroup]="formValue" novalidate>
                  <h2 class="LoginH2"><b>Forgot Password</b></h2>
                  <div>
                    <label class="form-label text-center">
                      <b>To resend your password, provide<br> the registered email-id.</b>
                    </label>
                  </div>
                  <div class="form-floating mb-3">
                    <input type="text" class="form-control" id="name" formControlName="email" placeholder="Enter Email" autocomplete="off">
                    <label for="forName">Email</label>
                  </div>
                  <!-- Buttons -->
                  <div class="text-center">
                    <!-- [routerLink]="['/layout/dashboard']" -->
                    <!-- <button type="submit" class="btn btn-outline-primary" (click)="resetPassword()">
                      Reset-Password <span class="spinner-border spinner-border-sm" *ngIf="loading" role="status" aria-hidden="true"></span>
                    </button> &nbsp;&nbsp; -->
                    <button type="submit" class="btn btn-outline-primary" (click)="resendPassword()">
                      <b>
                        Resend Password <span class="spinner-border spinner-border-sm" *ngIf="loading" role="status" aria-hidden="true"></span>
                      </b>
                    </button> &nbsp;
                    <button type="submit" class="btn btn-outline-danger" (click)="onCancelClicked()">
                      <b>
                        Cancel
                      </b>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- All Rights reserved - v1.0.1 -->
        <div class="text-center mt-2">
          <p class="cardFooter" style="color: white;"> © 2024 EDC. All rights reserved. <span> v1.0.4</span></p>
        </div>
      </div>
    </div>
  </div>
</section>  