<form [formGroup]="filterForm"> 
    <div class="row">
      <div class="col" *ngIf="isShowSiteFilter">
        <div class="form-floating mb-2">
          <ng-multiselect-dropdown class="dropdown-list" 
                                   placeholder="Site" 
                                   formControlName="site" 
                                   [settings]="siteDropDownConfiguration"
                                   [data]="siteList"
                                   (onDeSelect)="resetDropDownSelection('subject')" 
                                   (onDeSelectAll)="resetDropDownSelection('subject')" 
                                   (onSelect)="resetDropDownSelection('subject')" 
                                   (onSelectAll)="resetDropDownSelection('subject')">
          </ng-multiselect-dropdown>
        </div>
      </div>

      <div class="col" *ngIf="isShowSubjectFilter">
        <div class="form-floating mb-2">
          <ng-multiselect-dropdown class="dropdown-list" 
                                  placeholder="Subject" 
                                  formControlName="subject"
                                  [settings]="subjectDropDownConfiguration"
                                  [data]="subjectList">
          </ng-multiselect-dropdown>
        </div>
      </div>

      <div class="col" *ngIf="isShowVisitFilter">
        <div class="form-floating mb-2">
          <ng-multiselect-dropdown class="dropdown-list"
                                   formControlName="visit"
                                   placeholder="Visit"
                                   [settings]="visitDropdownConfiguration"
                                   [data]="visitList"
                                   (onDeSelect)="resetDropDownSelection('form')"
                                   (onDeSelectAll)="resetDropDownSelection('form')"
                                   (onSelect)="resetDropDownSelection('form')" 
                                   (onSelectAll)="resetDropDownSelection('form')">
          </ng-multiselect-dropdown>
        </div>
      </div>
      <div class="col" *ngIf="isShowFormFilter">
        <div class="form-floating mb-2">
          <ng-multiselect-dropdown class="dropdown-list"
                                   formControlName="form"
                                   placeholder="Form"
                                   [settings]="formDropdownConfiguration"
                                   [data]="formList">
          </ng-multiselect-dropdown>
        </div>
      </div>

      <div class="col text-center">
          <div class="d-flex justify-content-center gap-3">
          <div class="col">
            <div class="form-floating mb-2">
              <button class="btn btn-outline-success" *ngIf="!isUploadCancel" (click)="onApplyFilter()">
                <b>Apply</b>
              </button>
            </div>
          </div>
          
          <ng-container *ngIf="isShowUploadButton && roles === crc">
            <div class="col">
              <div class="form-floating mb-2">
                <button *ngIf="!isUploadCancel" class="btn btn-outline-primary" (click)="uploadFile()">
                  <b>Upload</b>
                </button>
              </div>
            </div>
            <ng-container *ngIf="isUploadCancel">
              <div class="col">
                <div class="form-floating mb-2">
                  <button class="btn btn-outline-success" (click)="uploadFile()">
                    <b>Go</b>
                  </button>
                </div>
              </div>

              <div class="col">
                <div class="form-floating mb-2">
                  <button class="btn btn-outline-danger" (click)="cancelUpload('Cancel')">
                    <b>Back</b>
                  </button>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <div class="col">
            <div class="form-floating mb-2">
              <button class="btn btn-outline-danger" *ngIf="!isUploadCancel" (click)="onClearFilter()">
                <b>Clear Filter</b>
              </button>
            </div>
          </div>
      </div>
    </div>
  </div>
</form>