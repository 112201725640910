import { baseURL , VERSION } from 'src/app/constant/constant';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Site } from '../types/IdNameType';
import type { ResponseSiteSubject } from '../models/response/responseSiteSubject';

@Injectable({
  providedIn: 'root'
})
export class SiteService {
  
  constructor(private http: HttpClient) { }

  postSite(data: any):Observable<any>{
    return this.http.post(`${baseURL}site/${VERSION}`, data);
  }

  getListSite(pageNo:any,pageSize:any,codes?:any[],emailIds?:any[], siteIds?:any[]):Observable<any>{
    let url:string = `${baseURL}site/${VERSION}/list?pageNo=${pageNo}&pageSize=${pageSize}`
    
    if(siteIds && siteIds.length > 0){
      url = url.concat(`&siteIds=${siteIds}`)
    }
    if(codes && codes.length > 0){
      url = url.concat(`&codes=${codes}`)
    }
    if(emailIds && emailIds.length > 0){
      url = url.concat(`&emailIds=${emailIds}`)
    }
    return this.http.get(url);
    
  }


  getSiteIdName(studyId : string|null):Observable<{status:number;message:string;responseObject:Site[]}>{
    let url = `${baseURL}site/${VERSION}/siteNames?studyId=${studyId}`;
    if(studyId == null){
      url = `${baseURL}site/${VERSION}/siteNames`;
    }
    return this.http.get<{status:number;message:string;responseObject:Site[]}>(url);
  }

  getUpdateSite(id:any):Observable<any>{
    return this.http.get(`${baseURL}site/${VERSION}?id=${id}`);
  }

  // getAdminSite():Observable<any>{
  //   return this.http.get(`${baseURL}site/${VERSION}/adminList`);
  // }

  updateSite(id:string,data: any):Observable<any>{
    return this.http.put(`${baseURL}site/${VERSION}`,data);
  }

  deleteSite(id: string):Observable<any>{
    const params = new HttpParams({ fromObject: { id: id } })
    return this.http.delete(`${baseURL}site/${VERSION}?id=${id}`);
  }

  getUserSiteData():Observable<any>{
    return this.http.get(`${baseURL}site/${VERSION}/study`);
  }

  getSiteFilterList(userId:any,studyId:any,roleId:any):Observable<any>{
    return this.http.get(`${baseURL}site/${VERSION}/siteFilterList?userId=${userId}&studyId=${studyId}&roleId=${roleId}`)
  }

  getAllSiteSubjectData():Observable<any>{
    return this.http.get(`${baseURL}common/${VERSION}/site-subject`);
  }

   getAllSiteSubjectInfo():Observable<{status:number;responseObject:Map<number, ResponseSiteSubject>}>{
    return this.http.get<{status:number;responseObject:Map<number, ResponseSiteSubject>}>(`${baseURL}common/${VERSION}/site-subject`);
  }

  getAllSiteSubjectDataByStudyId(studyId:any):Observable<any>{
    return this.http.get(`${baseURL}common/${VERSION}/studyId/${studyId}/site-subject`);
  }
}