import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, tap } from 'rxjs';
import { baseURL , VERSION } from 'src/app/constant/constant';
import { Form as FormIdName } from 'src/app/types/IdNameType';
import type { ResponseForm, ResponseVisitForm } from '../models/response/responseVisitForm';
import type { ResponseField } from '../models/response/responseField';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  private dataIndex = new BehaviorSubject<any>(null);
  private dataFormId = new BehaviorSubject<any>("");
  currentIndex = this.dataIndex.asObservable();
  currentFormEdit = this.dataFormId.asObservable();
  __visitFormData: any;

  constructor(private http: HttpClient) { }
  editFormId(formId: any) {
    this.dataFormId.next(formId);
  }

  getUser(id: any): Observable<any> {
    return this.http.get(`${baseURL}users/${VERSION}?id=${id}`);
  }

  postIndex(currentIndex: any) {
    this.dataIndex.next(currentIndex);
  }

  postForm(data: any): Observable<any> {
    return this.http.post(`${baseURL}forms/${VERSION}`, data);
  }

  getForm(): Observable<any> {
    return this.http.get(`${baseURL}forms/${VERSION}/list`);
  }

  getFormById(id: any): Observable<any> {
    return this.http.get(`${baseURL}forms/${VERSION}?id=${id}`);
  }

  updateForm(data: any): Observable<any> {
    return this.http.put(`${baseURL}forms/${VERSION}`, data);
  }

  deleteForm(id: string): Observable<any> {
    return this.http.delete(`${baseURL}forms/${VERSION}?id=${id}`);
  }

  hardDeleteForm(id: string): Observable<any> {
    return this.http.delete(`${baseURL}forms/${VERSION}/hardDelete?formIdList=${id}`);
  }

  getFieldValidationData(formId:any,visitId:any):Observable<any>{
    let url:string = `${baseURL}forms/${VERSION}/fieldValidation?formId=${formId}&visitId=${visitId}`
    return this.http.get(url);
  }

  getImageData(formId: string): Observable<any> {
    const url = `${baseURL}forms/${VERSION}/fields/image?formId=${formId}`;
    return this.http.get(url);
  }

  getVisitFormData(subjectIds:any, isApiCall: boolean = true):Observable<{ status:number; responseObject:{visit: Map<string, { isLocked : boolean|null; multiViewEnable : boolean|null; name : string; orderId : number; forms: {formId: number; formName: string; formType:number;}[];}>;} }>{
    if (isApiCall) {
      return this.http.get<{ status:number; responseObject:{visit: Map<string, { isLocked : boolean|null; multiViewEnable : boolean|null; name : string; orderId : number; forms: {formId: number; formName: string; formType:number;}[];}>;} }>(`${baseURL}common/${VERSION}/visitForm?subjectIds=${subjectIds}`).pipe(
        tap((res: { status:number; responseObject:{visit: Map<string, { isLocked : boolean|null; multiViewEnable : boolean|null; name : string; orderId : number; forms: {formId: number; formName: string; formType:number;}[];}>;} }) => {
          this.__visitFormData = res;
        })
      );
    } else {
      return of(this.__visitFormData);
    }
  }

    getVisitFormInfo(subjectIds:any, isApiCall: boolean = true):Observable<{ status:number; responseObject: Map<string, ResponseVisitForm> }>{
    if (isApiCall) {
      return this.http.get<{ status:number; responseObject: Map<string, ResponseVisitForm> }>(`${baseURL}common/${VERSION}/visitForm?subjectIds=${subjectIds}`).pipe(
        tap((res: { status:number; responseObject: Map<string, ResponseVisitForm> }) => {
          this.__visitFormData = res;
        })
      );
    } else {
      return of(this.__visitFormData);
    }
  }

  setVisitFormData = (data: any): void => {
    this.__visitFormData = data;
  }

  
  getLogFormInfo():Observable<{ status:number; responseObject:Map<string, ResponseVisitForm> } >{
    return this.http.get<{ status:number; responseObject:Map<string, ResponseVisitForm>}>(`${baseURL}common/${VERSION}/logform`);
  }

    getLogFormAPIData():Observable<{ status:number; responseObject:{visit: Map<string, { isLocked : boolean|null; multiViewEnable : boolean|null; name : string; orderId : number; forms: {formId: number; formName: string; formType:number;}[];}>;} }>{
    return this.http.get<{ status:number; responseObject:{visit: Map<string, { isLocked : boolean|null; multiViewEnable : boolean|null; name : string; orderId : number; forms: {formId: number; formName: string; formType:number;}[];}>;} }>(`${baseURL}common/${VERSION}/logform`);
  }

  // TODO: If extra data have not required then use :: getLogFormAPIData()
  getLogFormsData():Observable<{status:number;message:string;responseObject: FormIdName[]}>{
    return this.http.get<{status:number;message:string;responseObject: FormIdName[]}>(`${baseURL}forms/${VERSION}/logForms`);
  }
  
  getFormData(id:any):Observable<any>{
    return this.http.get(`${baseURL}forms/${VERSION}/formData?id=${id}`);
  }

  setOrderId(data:any):Observable<any>{
    return this.http.put(`${baseURL}forms/${VERSION}/orderId`,data);
  }

  getStudyCompletionForm():Observable<any>{
    return this.http.get(`${baseURL}forms/${VERSION}/studyCompletion`);
  }

  getLogFormField():Observable<any>{
    return this.http.get(`${baseURL}forms/${VERSION}/logFormField`);
  }

  getFormType(formId:any):Observable<any>{
    return this.http.get(`${baseURL}forms/${VERSION}/formType?formId=${formId}`);
  }

  getAllDataStudyWise():Observable<any>{
    return this.http.get(`${baseURL}forms/${VERSION}/idName`);
  }

  exportForms(data:any):Observable<any>{
    return this.http.post(`${baseURL}export/form`,data);
  }

  getFieldData(): Observable<{status:number; responseObject: ResponseField[];}> {
    return this.http.get<{status: number; responseObject: ResponseField[]; }>(`${baseURL}forms/${VERSION}/fields`);
  }
}