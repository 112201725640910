import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import type { ResponseForm, ResponseVisitForm } from 'src/app/models/response/responseVisitForm';

@Injectable({
  providedIn: 'root',
})

export class VisitFormStoreService {
  private visitFormData = new BehaviorSubject<Map<string, ResponseVisitForm>>(new Map());
  visitForms$ = this.visitFormData.asObservable();

  private logFormData = new BehaviorSubject<Map<string, ResponseVisitForm>>(new Map());
  logForms$ = this.logFormData.asObservable();
  constructor() {}

  // Get the observable to subscribe in components
  getVisitFormData(): Map<string, ResponseVisitForm> {
    return this.visitFormData.getValue();
  }

  getLogFormData(): Map<string, ResponseVisitForm> {
    return this.logFormData.getValue();
  }

  setVisitFormData(data: Map<string, ResponseVisitForm> | null): void {

    if (!data || data.size === 0) {
      this.visitFormData.next(new Map());
      return;
    }
    this.visitFormData.next(data);
  }

  setLogFormData(data: Map<string, ResponseVisitForm> | null): void {

    if (!data || data.size === 0) {
      this.logFormData.next(new Map());
      return;
    }
    this.logFormData.next(data);
  }
}