<div class="container-fluid">
  <div class="row">
    <div class="row mb-1">
      <div class="col">
        <p class="heading">Notification Configuaration</p>
      </div>
      <div class="col text-end">
        <button (click)="goToAddNotificationConfiguration()" class="btn btn-outline-primary mt-3" >
          <b>Add Notification</b>
        </button>
      </div>
    </div>
  </div>
</div>
<hr/>
<div>
  <div *ngIf="notificationDataList.length; else noData">
    <table class="table table-hover table-light">
      <thead class="bg-light sticky-top">
        <tr>
          <th scope="col">Sr. No.</th>
          <th scope="col">Configuration Name</th>
          <th scope="col">Status</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of notificationDataList; let i = index;">
          <td scope="row">{{serialNumber + i}}</td>
          <td>{{row.name}}</td>
          <td>{{row.status == 1? 'Active':'Inactive'}}</td>
          <td>
            <button class="control-buttons" title="Update" (click)="gotoEdit(row)">
              <span class="updateIcon"></span>
            </button> &nbsp;
            <button class="control-buttons" title="Delete" (click)="onDelete(row.id)">
              <span class="deleteIcon"></span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="tableFooterHr"></div>
    
    <ngb-pagination [collectionSize]="totalPages??0" [(page)]="page" [boundaryLinks]="true" [rotate]="true"
    [ellipses]="false" (pageChange)="onChangePagination()">
    <ng-template ngbPaginationPages let-page let-pages="pages">
      <li class="ngb-custom-pages-item" *ngIf="pages.length > 0">
        <div class="mb-3 d-flex flex-nowrap px-2">
          <label id="paginationInputLabel" for="paginationInput" class="col-form-label me-2 ms-1">Page</label>
          <input #i type="text" inputmode="numeric" pattern="[0-9]*" class="form-control custom-pages-input"
            id="paginationInput" [value]="page" (keyup.enter)="selectPage(i.value)" (blur)="selectPage(i.value)"
            (input)="formatInput($any($event).target)" aria-labelledby="paginationInputLabel paginationDescription"
            style="width: 2.3em" />
          <span id="paginationDescription" class="col-form-label text-nowrap px-2"> of {{pages.length}}</span>
        </div>
      </li>
    </ng-template>
  </ngb-pagination>
  </div>
  <ng-template #noData>
    <h5>No Data Available! &nbsp;
      <button (click)="goToAddNotificationConfiguration()" class="btn btn-outline-primary mt-3" >
        <b>Add Notification</b>
      </button>
    </h5>
  </ng-template>
</div>