import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { visibility } from 'html2canvas/dist/types/css/property-descriptors/visibility';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { jsonData } from 'src/app/constant/constant';
import { COPY_FORM_ERR_MESSAGE, FORM_ANNOTATEDNAME_ERROR_MSG, FORM_FORMTYPE, FORM_NAME_ERROR_MSG } from 'src/app/constant/responseMessage';
import { Role } from 'src/app/models/Role';
import { Form } from 'src/app/models/form';
import { FormService } from 'src/app/services/form.service';
import { StudyService } from 'src/app/services/study.service';
import { VisitsService } from 'src/app/services/visits.service';

@Component({
  selector: 'app-form-detail-popup',
  templateUrl: './form-detail-popup.component.html',
  styleUrls: ['./form-detail-popup.component.css']
})
export class FormDetailPopupComponent implements OnInit{
  @Input() showAdd: any;
  @Input() showUpdate: any;
  @Input() isCopyForm: any;
  @Input() rowId: any;
  @Input() item: any;
  @Input() copyFormRowId: any;
  @Input() isDisabled!: boolean;
  @Input() isButtonDisplay!: boolean;
  @Input() responseData: any;
  @Input() updateFormVisitId!:string;

  @Output() modalYesClicked: EventEmitter<any> = new EventEmitter();
  @Output() addFieldClicked: EventEmitter<any> = new EventEmitter();

  formValue!: FormGroup;
  studyName: any;
  formData: any[] = [];
  formVisitsData?: any;
  formObj: Form = new Form();
  formTypeData = [
    {
      name: "Normal Form",
      value: 0,
    },
    {
      name: "Log Form",
      value: 1,
    },
    {
      name: "Study Completion Form",
      value: 2,
    },
  ];

  userName: any;
  dropdownList: any = [];
  selectedItems: any = [];
  dropdownSettings: IDropdownSettings = {};
  studyDropDownSettings: IDropdownSettings = {};
  loading: boolean = false;
  copyFormLoader: boolean = false;
  formNameTitle: any;
  studyId: any;
  studyData: any;
  roles: any;
  userId: any;
  copyFormData: any;
  formName: any;
  formType: any;
  visitIds: any;
  formAnnotatedName: any;
  readOnlyRole:any;
  studyListByRoleId: any;

  visibilityData = [ // To Add more roles, add more objects in this array
    { roleId: 9, label: 'Lab CRC' },
  ];

  constructor(
    private formAPI: FormService,
    private visitAPI: VisitsService,
    private toastr: ToastrService,
    private router: Router,
    private modelService: NgbModal,
    private studyAPI: StudyService
  ) {
    this.formValue = new FormGroup({
      id: new FormControl("", []),
      name: new FormControl("", []),
      annotatedName: new FormControl("", []),
      studyId: new FormControl("", []),
      formType: new FormControl(this.formTypeData[0].value),
      visibility: new FormArray([]),
      // formTypeData:new FormControl(),
      selectedStudyId: new FormControl(),
      visitIds: new FormControl('', []),
      fields: new FormArray([]),
      copyValidation: new FormControl(false),
      copyFormula: new FormControl(false),
      fileUploadAllowed: new FormControl(true),
    });
    this.addCheckboxes();
  }

  private addCheckboxes() {
    this.visibilityData.forEach(() => this.visibilityFormArray.push(new FormControl(false)));
  };

  get visibilityFormArray() {
    return this.formValue.controls.visibility as FormArray;
  };

  get formtype() {
    return this.formValue.controls.formType as FormControl;
  };

  ngOnInit() {
    this.readOnlyRole = Role.ReadOnly;
    // localStorage.clear();
    this.getStorageData();
    let formObject: any = {};
    this.multiselect();
    if (!this.studyId && this.userName == "admin") {
      this.getAllStudyData();
    }
    this.setStudyListByRoleId();
    this.getAllVisits();

    if (this.rowId && this.rowId !== 'null') {
      this.formAPI.getFormData(this.rowId).subscribe((res) => {
        console.log("RESPONSE :: ",res);
        const data: any = res.responseObject[0];
        if (data.formType == 2) {
          this.formValue.get("formType")?.setValue(data.formType);
          // delete data.visits
        } else if (data.formType == 1) {
          this.formValue.get("formType")?.setValue(data.formType);
        } else {
          this.formValue?.controls["visitIds"].setValue(data.visits);
        }
        this.formValue.patchValue(res.responseObject[0]);
        this.setVisibilityCheckBoxValue(res);
      });
    }else{
      formObject.name = this.formName;
      formObject.annotatedName = this.formAnnotatedName;
      formObject.formType = this.formType;
      formObject.formType = this.formType
      this.visitAPI.visitList$$.subscribe((visit: any)=>{
        formObject.visitIds = visit

      })
      this.formValue.patchValue(formObject);
    }

    if (this.copyFormRowId) {
      this.copyForm()
    }
  }

  setStudyListByRoleId = (): void => {
    const options = this.getOptions();
    this.studyAPI.getStudyByRoleId(options)?.subscribe({
      next: (res) => {
        this.studyListByRoleId = res.responseObject;
        this.formValue.get('selectedStudyId')?.setValue([{
          id: this.studyId,
          name: this.studyName
        }])
      },
      error: () => {
        this.studyListByRoleId = [];
      }
    });
  }

  getOptions = (): any => {
    return {
      params: {
        roleId: 1
      }
    }
  }

  getAllVisits = (selectedStudyId?: any): void => {
    const studyId = selectedStudyId ? selectedStudyId : this.studyId;
    this.visitAPI.getVisitListByStudyId({params: {studyId: studyId}}).subscribe({
      next: (res) => {
        this.dropdownList = res.responseObject;
      },
      error: () => {
        this.dropdownList = [];
      }
    });
  }

  private setVisibilityCheckBoxValue(res: any) {
    this.visibilityData.forEach((v: any, index: number) => { 
      if (res.responseObject[0]?.roles.includes(v.roleId)) { 
        this.visibilityFormArray.controls.find((checkbox: any, checkboxIndex: number) => index === checkboxIndex)?.setValue(true); 
      } 
    });
  }

  getAllForms() {
    this.formAPI.getForm().subscribe((res) => {
      console.log("RESPONSE :: ",res);
      this.formData = res.responseObject;
      for (let index = 0; index < this.formData.length; index++) {
        this.formVisitsData = this.formData[index].visits;
      }
    });
  }

  getAllStudyData() {
    this.studyAPI.getStudyIdNameList().subscribe((res) => {
      console.log("RESPONSE :: ",res);
      this.studyData = res.responseObject;
    });
  }

  getStorageData() {
    this.roles = sessionStorage.getItem("role");
    this.userId = sessionStorage.getItem("userId");
    this.userName = sessionStorage.getItem("userName");
    this.studyId = sessionStorage.getItem("studyId");
    this.studyName = sessionStorage.getItem("studyName");
    this.formName = localStorage.getItem("formName");
    this.formType = localStorage.getItem("formType") ? Number(localStorage.getItem("formType")) : this.formTypeData[0].value;
    this.visitIds = localStorage.getItem("visitIds");
    this.formAnnotatedName = localStorage.getItem("formAnnotatedName");
  }

  multiselect() {
    this.dropdownList = [];
    this.selectedItems = [];
    this.dropdownSettings = {
      singleSelection: false,
      idField: "id",
      textField: "name",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 4,
      allowSearchFilter: true,
    };
    this.studyDropDownSettings = {
      singleSelection: true,
      idField: "id",
      textField: "name",
      itemsShowLimit: 4,
      allowSearchFilter: true
    };
  }

  getVisitByStudy() {
    this.visitAPI.getVisitsBySubjectId().subscribe((res) => {
      this.dropdownList = res.responseObject;
    });
  }

  closeModal() {
    this.modelService.dismissAll();
    this.formValue.reset();
    // if(this.isCopyForm){
    //   this.getAllVisits()
    // }
    this.formValue.get("formType")?.enable();
    this.formValue.get("formTypeData")?.enable();
    // this.formValue.get("visitIds")?.disable()
  }

  validateData() {
    if (this.formValue?.value.formType) {
      let res = Number(this.formValue.value.formType);
      this.formValue.value.formType = res;
    }
    let formData = this.formValue?.getRawValue();
    if (formData.name == "" || formData.name == null || formData.name.toString().trim() == '') {
      this.toastr.warning(FORM_NAME_ERROR_MSG);
      return false;
    } else if (formData.annotatedName == "" || formData.annotatedName == null || formData.annotatedName.toString().trim() == '') {
      this.toastr.warning(FORM_ANNOTATEDNAME_ERROR_MSG);
      return false;
    } else if (!this.formValue?.get('selectedStudyId')?.value[0]?.id) {
      this.toastr.warning(COPY_FORM_ERR_MESSAGE);
      return false;
    } else if (
      (formData.formType == 0 || formData.formType == null) &&
      (formData.visitIds == null || formData.visitIds.length == 0)
    ) {
      this.toastr.warning(FORM_FORMTYPE);
      return false;
    } else {
      return true;
    }
  }

  saveForm() {
    let d = [];
    const selectedVisibilityRoleIds = this.formValue.value.visibility.map((v:string, i:number) => v ? this.visibilityData[i].roleId : null).filter((v:string) => v !== null);
    if (
      this.formValue.get("formType")?.value == "" ||
      this.formValue.get("formType")?.value == null
    ) {
      this.formValue.get("formType")?.setValue(0);
    }
    this.formObj.id = this.formValue.value.id ? this.formValue.value.id : null;
    this.formObj.name = this.formValue?.getRawValue().name.toString().trim();
    this.formObj.annotatedName = this.formValue?.getRawValue().annotatedName.toString().trim();
    this.formObj.formType = this.formValue.value.formType? this.formValue.value.formType: 0;
    this.formObj.fileUploadAllowed = this.formValue.value.fileUploadAllowed
    this.formObj.roles = selectedVisibilityRoleIds;
    console.log(this.formValue);
    if (this.formValue?.getRawValue().visitIds) {
      this.formObj.visitIds = this.formValue?.getRawValue().visitIds;
      for (let j = 0; j < this.formValue?.getRawValue().visitIds.length; j++) {
        d.push(this.formValue?.getRawValue().visitIds[j].id);
      }
      this.formObj.visitIds = d;
    }
    this.loading = true;
    if (this.formObj.id != null) {
      this.formObj.visitIds = d;
      // this.localStorageData()
      if (this.validateData()) {
        if (this.userName == "admin") {
          this.formObj.studyId = this.formValue.get("studyId")?.value;
        }
        this.formAPI.updateForm(this.formObj).subscribe((res) => {
          console.log("RESPONSE :: ",res);
          if (res.status != 0) {
            this.toastr.error(res.message);
          } else {
            this.toastr.success(res.message);
            // localStorage.setItem('formAnnotatedName', res.responseObject.annotatedName)
            // localStorage.setItem('formName', res.responseObject.name)
            this.modalYesClicked.emit(res.responseObject)
            let ref = document.getElementById("cancel");
            ref?.click();
            // if (!this.isButtonDisplay){
            //   this.getAllForms();
            // }
            // if (!this.isButtonDisplay && res.responseObject) {
            //   this.getAllForms();
            // }
            // localStorage.removeItem('formAnnotatedName');
            // localStorage.removeItem('formName');
          }
          this.loading = false;
        },error => {
          this.loading = false;
        });
      }else{
        this.loading = false;
      }
    } else {
      if (this.validateData()) {
        if (this.userName == "admin") {
          this.formObj.studyId = this.formValue.get("studyId")?.value;
        }
        this.formAPI.postForm(this.formObj).subscribe({
          next:(res) => {
            console.log("RESPONSE :: ",res);
            if (res.status != 0) {
              this.toastr.error(res.message);
            } else {
              localStorage.setItem('editFormId', res.responseObject.id)
              localStorage.setItem('formId', res.responseObject.id)
              this.toastr.success(res.message);
              let ref = document.getElementById("cancel");
              ref?.click();
              this.modalYesClicked.emit(res.responseObject)
              // this.getAllForms();
            }
            this.loading = false;
          },
          error:(error) => {
            this.loading = false;
          }
        });
      }else{
        this.loading = false;
      }
    }
  }

  editFormFields() {
    if (this.validateData()) {
      let d = [];
      let formData = this.formValue?.getRawValue();
      this.formObj.id = formData.id;
      this.formObj.name = formData.name;
      this.formObj.annotatedName = formData.annotatedName;
      this.formObj.visitIds = formData.visitName;
      this.formObj.fileUploadAllowed = formData.fileUploadAllowed;
      if (formData.visitIds) {
        for (let j = 0; j < formData.visitIds.length; j++) {
          d.push(formData.visitIds[j].id);
        }
      }else{
        d.push(this.updateFormVisitId);
      }
      this.formValue?.controls["visitIds"].setValue(d);
      this.localStorageData();
      this.router.navigate(["layout/dragDrop", { formId: formData.id }]);
      let ref = document.getElementById("cancel");
      ref?.click();
    }
  }

  addFormDetails() {
    if (this.validateData()) {
      let d = [];
      if (this.formValue?.getRawValue().visitIds) {
        this.visitAPI.visitList$$.next(this.formValue?.getRawValue().visitIds);

        for ( let j = 0;j < this.formValue?.getRawValue().visitIds.length;j++) {
          d.push(this.formValue?.getRawValue().visitIds[j].id);
        }
        this.formValue?.controls["visitIds"].setValue(d);
      }
      this.formObj.name = this.formValue?.getRawValue().name;
      this.formObj.annotatedName = this.formValue?.getRawValue().annotatedName;
      this.formObj.formType = this.formValue.value.formType
        ? this.formValue.value.formType
        : this.formValue.value.formTypeData;
      // this.formObj.formTypeData = this.formValue.value.formTypeData
      this.formObj.visitIds = this.formValue?.getRawValue().visitIds;
      this.formObj.fields = jsonData;

      this.formNameTitle = this.formValue?.getRawValue().name;

      this.localStorageData();
      this.sendToDB();
    }
  }

  copyFormDetail() {
    if (
      this.formValue.get("formType")?.value == "" ||
      this.formValue.get("formType")?.value == null
    ) {
      this.formValue.get("formType")?.setValue(0);
    }

    delete this.formObj.id;
    let formData = this.formValue?.getRawValue();
    this.formObj.name = formData.name;
    this.formObj.selectedStudyId = this.formValue?.get('selectedStudyId')?.value[0]?.id;
    this.formObj.annotatedName = formData.annotatedName;
    this.formObj.formType = this.formValue.value.formType;
    this.formObj.srcVisitId = this.item.id;
    this.formObj.srcFormId = this.copyFormRowId??this.copyFormRowId;
    this.formObj.isFormulaCopy = this.formValue.get('copyFormula')?.value;
    this.formObj.isValidationCopy = this.formValue.get('copyValidation')?.value;
    this.formObj.fileUploadAllowed = this.formValue.get('fileUploadAllowed')?.value;
    if (formData.visitIds) {
      let d: any = [];
      this.formObj.visitIds = formData.visitIds;

      for (let j = 0; j < formData.visitIds.length; j++) {
        d.push(formData.visitIds[j].id);
      }

      this.formObj.visitIds = d;
    }

    this.formObj.fields = this.formValue.value.fields;
    if (this.userName == "admin") {
      this.formObj.studyId = this.formValue.get("studyId")?.value;
    }
    if (this.validateData()) {
      this.copyFormLoader = true;
      this.formAPI.postForm(this.formObj).subscribe((res) => {
        console.log("RESPONSE :: ",res);
        if (res.status != 0) {
          this.toastr.error(res.message);
        } else {
          this.toastr.success(res.message);
          this.modalYesClicked.emit(res.responseObject)
          let ref = document.getElementById("cancel");
          ref?.click();
          // this.getAllForms();
          // this.getAllVisits();
        }
        this.copyFormLoader = false;
      },error => {
        this.copyFormLoader = false;
      });
    }
  }

  onSelectStudy = (): void => {
    this.formValue?.get("visitIds")?.setValue([]);
    this.getAllVisits(this.formValue?.get("selectedStudyId")?.value[0]?.id);
  }

  localStorageData() {
    localStorage.setItem("formName", this.formValue?.getRawValue().name);
    localStorage.setItem("formAnnotatedName",this.formValue?.getRawValue().annotatedName);
    localStorage.setItem("visitIds",JSON.stringify(this.formValue?.getRawValue().visitIds));

    if (this.formValue.get("formType")?.value == "" || this.formValue.get("formType")?.value == null) {
      this.formValue.get("formType")?.setValue(0);
    }

    localStorage.setItem("formType", this.formValue.get("formType")?.value);
    localStorage.setItem("editFormId", this.formValue?.getRawValue().id ? this.formValue?.getRawValue().id : null);
    localStorage.setItem("formId", this.formValue.value.id ? this.formValue.value.id : null);
  }

  sendToDB() {
    let ref = document.getElementById("cancel");
    ref?.click();
    // this.formValue?.reset();
    this.router.navigate(["layout/dragDrop"]);
  }

  copyForm() {
    console.log("COPY :: ",this.copyFormData);
    
    this.dropdownList = this.dropdownList.filter(
      (data: any) => data.id != this.item.id
    );

    // this.formAPI.getFormById(this.copyFormRowId).subscribe((res) => {
    // });
    
    if(this.responseData){
      this.copyFormData = this.responseData.responseObject;
    }

    if (this.copyFormData.formType == 2) {
      this.formValue.get("formTypeData")?.setValue(this.copyFormData.formType);
    } else if (this.copyFormData.formType == 1) {
      this.formValue.get("formType")?.setValue(this.copyFormData.formType);
    } else {
      //this.formValue?.controls["visitIds"].setValue([this.item.id]);
    }

    this.formValue.patchValue(this.copyFormData);
    this.formValue.get("name")?.setValue(String(this.copyFormData.formName) + "-copy");
    this.formValue.get("annotatedName")?.setValue(this.copyFormData.annotationName + "-copy");

    if (this.copyFormData.fieldValidationResponseDTOS && this.copyFormData.fieldValidationResponseDTOS.length > 0) {
      for (let i = 0; i < this.copyFormData.fieldValidationResponseDTOS.length; i++) {
        delete this.copyFormData.fieldValidationResponseDTOS[i].id;

        if (
          this.copyFormData.fieldValidationResponseDTOS[i].options &&
          this.copyFormData.fieldValidationResponseDTOS[i].options.length > 0
        ) {
          for (
            let j = 0;
            j < this.copyFormData.fieldValidationResponseDTOS[i].options.length;
            j++
          ) {
            delete this.copyFormData.fieldValidationResponseDTOS[i].options[j].id;
            delete this.copyFormData.fieldValidationResponseDTOS[i].options[j].fieldId;
            delete this.copyFormData.fieldValidationResponseDTOS[i].options[j].multiRowId;
          }
        }

        if ( this.copyFormData.fieldValidationResponseDTOS[i].columnData && this.copyFormData.fieldValidationResponseDTOS[i].columnData.length > 0 ) {
          for (
            let k = 0;
            k < this.copyFormData.fieldValidationResponseDTOS[i].columnData.length;
            k++
          ) {
            delete this.copyFormData.fieldValidationResponseDTOS[i].columnData[k].id;
            delete this.copyFormData.fieldValidationResponseDTOS[i].columnData[k].fieldId;

            if (
              this.copyFormData.fieldValidationResponseDTOS[i].columnData[k].options &&
              this.copyFormData.fieldValidationResponseDTOS[i].columnData[k].options.length > 0
            ) {
              for (
                let l = 0;
                l < this.copyFormData.fieldValidationResponseDTOS[i].columnData[k].options.length;
                l++
              ) {
                delete this.copyFormData.fieldValidationResponseDTOS[i].columnData[k].options[l]
                  .id;
                delete this.copyFormData.fieldValidationResponseDTOS[i].columnData[k].options[l]
                  .fieldId;
                delete this.copyFormData.fieldValidationResponseDTOS[i].columnData[k].options[l]
                  .multiRowId;
              }
            }
          }
        }

        if ( this.copyFormData.fieldValidationResponseDTOS[i].cellInfo && this.copyFormData.fieldValidationResponseDTOS[i].cellInfo.length > 0 ) {
          for (let rowCount = 0; rowCount < this.copyFormData.fieldValidationResponseDTOS[i].cellInfo.length; rowCount++) {
            for (let columnCount = 0; columnCount < this.copyFormData.fieldValidationResponseDTOS[i].cellInfo[rowCount].length; columnCount++) {
              for (let cellCount = 0; cellCount < this.copyFormData.fieldValidationResponseDTOS[i].cellInfo[rowCount][columnCount].length; cellCount++) {
                delete this.copyFormData.fieldValidationResponseDTOS[i].cellInfo[rowCount][columnCount][cellCount].id
                delete this.copyFormData.fieldValidationResponseDTOS[i].cellInfo[rowCount][columnCount][cellCount].parentFieldId
                if(this.copyFormData.fieldValidationResponseDTOS[i].cellInfo[rowCount][columnCount][cellCount].options && 
                this.copyFormData.fieldValidationResponseDTOS[i].cellInfo[rowCount][columnCount][cellCount].options.length > 0){
                  for (let op = 0; op < this.copyFormData.fieldValidationResponseDTOS[i].cellInfo[rowCount][columnCount][cellCount].options.length; op++) {
                    delete this.copyFormData.fieldValidationResponseDTOS[i].cellInfo[rowCount][columnCount][cellCount].options[op].id
                    delete this.copyFormData.fieldValidationResponseDTOS[i].cellInfo[rowCount][columnCount][cellCount].options[op].fieldId
                    
                  }
                }
              }
            }
          }
        }

        (this.formValue.get("fields") as FormArray).push(
          new FormControl(this.copyFormData.fieldValidationResponseDTOS[i])
        );
      }
    }
    console.log(this.formValue.value);
  }

  isDisabledRadio(){
    let setDisable = false;
    if (this.isDisabled) {
      setDisable = true;
    }else{
      setDisable = false;
    }
    return setDisable;
  }

  emitAnnotation(){
    let annotedName = this.formValue.get("annotatedName")?.value
    let formName = this.formValue.get("name")?.value
    let formId = localStorage.getItem('formId')
    if(formId !== 'null'){
      this.saveForm()
    }else{
      localStorage.setItem('formAnnotatedName', annotedName)
      localStorage.setItem('formName', formName)
      let formData = {
        formName: formName,
        annotedFormName: annotedName,
        isButtonDisplay: true
      }
      this.addFieldClicked.emit(formData);
    }
    this.modelService.dismissAll();

  }

}
