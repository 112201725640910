import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import type { ResponseUser } from 'src/app/models/response/responseUser';


@Injectable({
  providedIn: 'root',
})
export class UserStoreService {
  private userData = new BehaviorSubject<ResponseUser[]>([]);
  userData$ = this.userData.asObservable();

  constructor() {}

  getSiteSubjectData(): ResponseUser[] {
    return this.userData.getValue();
  }

  setUserData(data: ResponseUser[]): void {

    if (!data || data.length === 0) {
      this.userData.next([]);
      return;
    }

    this.userData.next(data);
  }
}