import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Notification } from 'src/app/constant/export-types';
import { Role } from 'src/app/models/Role';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {

  notificationDataList: Notification[] = []; 
  studyId: string | null = null; 
  totalCount: number = 10; // Total number of notifications
  pageNo: number = 0; // Current page
  pageSize: number = 10; // Items per page
  totalPages: number|null = 10; // Total number of pages
  page: number = 1;
  serialNumber: number = 1;

  notificationId: number | null = null;
  notificationData: any = null;

  constructor(private notificationService: NotificationService, private router: Router, private toastr: ToastrService) {}

  ngOnInit(): void {
    this.getSessionStorage();
    this.studyId = sessionStorage.getItem("studyId")
    if (this.studyId) {
      this.getNotificationData();
    } else {
      console.error('Study ID not found in session storage');
    }
    
  }

  getSessionStorage(): void {
    if(Number(sessionStorage.getItem("role")?.toString()) !== Role.DM ){
      sessionStorage.clear();
      this.router.navigateByUrl('/');
      return;
    };
    this.studyId = sessionStorage.getItem("studyId")?.toString() || '';
    if(this.studyId == ''){
      this.toastr.error('Study Id not found');
    };
  }

  getNotificationData() {
    this.notificationService.getNotificationList(this.studyId, this.pageNo, this.pageSize).subscribe(
      (response: any) => {
        if(response.status === 0){
          this.notificationDataList = response.responseObject.notificationList;
          this.totalPages = response.responseObject.totalCount;
        }else{
          this.notificationDataList = [];
          this.totalPages = null;
          this.toastr.error(response.message);
        };
      },
      (error) => {
        console.error('Error fetching notifications:', error);
      }
    );
  }

  editNotification(config: any){
    this.router.navigateByUrl('/layout/notification-configuration', { state: { edit: true, id: config.id } });
  }
    
  deleteNotification(id: number) {
    console.log("Delete",id)
    if (confirm('Are you sure you want to delete this notification?')) {
      this.notificationService.deleteConfiguration(id).subscribe({
        next: () => {
          this.toastr.success('Notification deleted successfully!');
          this.notificationDataList = this.notificationDataList.filter(
            (notification) => notification.id !== id
          );
          this.getNotificationData();
        },
        error: (error) => {
          console.error('Error deleting notification:', error);
          this.toastr.error('Failed to delete notification');
        }
      });
    }
  };

  gotoEdit(row: Notification) {
    this.router.navigateByUrl('/layout/notification-configuration', { state: { id: row.id } });
  };

  goToAddNotificationConfiguration(){
    this.router.navigateByUrl('/layout/notification-configuration');
  };

  onDelete(notificationId: number) {
    if (window.confirm(`are you sure you want to delete this notification? ` )) {
      this.notificationService.deleteConfiguration(notificationId).subscribe({
        next: (res) => {
          if(res.status === 0){
            this.toastr.success('Notification deleted successfully!');
            this.getNotificationData();
          }else{
            this.toastr.error(res.message);
          }
        },
        error: (error) => {
          console.error('Error deleting notification:', error);
          this.toastr.error('Failed to delete notification');
        }
      });
    }
  };

  formatInput(input: HTMLInputElement) {
    const FILTER_PAG_REGEX = /[^0-9]/g;
    input.value = input.value.replace(FILTER_PAG_REGEX, '');
  };

  selectPage(page: string) {
    this.page = parseInt(page, 10) || 1;
    this.pageNo = this.page - 1;
    this.serialNumber = this.page == 1 ? 1 : (this.page - 1) * 10 + 1;
    this.getNotificationData();
  };

  onChangePagination() {
    this.pageNo = this.page - 1
    this.serialNumber = this.page == 1 ? 1 : (this.page - 1) * 10 + 1;
    this.getNotificationData();
  };
};

