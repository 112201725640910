import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { DynamicFilterComponent } from "./dynamic-filter.component";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
@NgModule({
    declarations: [
        DynamicFilterComponent
    ],
    imports: [
        CommonModule,
        NgMultiSelectDropDownModule,
        ReactiveFormsModule
    ],
    exports: [
        DynamicFilterComponent
    ]
})
export class DynamicFilterModule {

}