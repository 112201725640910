<!-- /* Try New LoginPage CSS */ -->
<section class="vh-100" style="background-color: #385b9b;">
  <div class="container py-5 h-100">
    <div class="row MainDiv h-100">
      <!-- Login Pannel Size According To Resolution -->
      <div class="col-sm col-md-10 col-lg-9 col-xl-7 animationLogin">
        <div class="card" style="border-radius: 1rem;">
          <div class="row g-0">
            <!-- Login Pannel Img -->
            <div class="col-sm col-md col-lg col-xl d-none d-md-block">
              <img src="../../../assets/Images/loginpage.webp" alt="login form" class="img-fluid ColImg"/>
            </div>
            <!-- Login Pannel -->
            <div class="col-sm col-md col-lg col-xl LoginPannel" *ngIf="isLoggedIn">
              <!-- Padding Spacing In Logout PANNEL -->
              <div class="card-body p-2 p-md-3 p-lg-4 p-xl-5">
                <form *ngIf="formGroup" [formGroup]="formGroup" (ngSubmit)="loginProcess()">
                  <!-- Meril Logo -->
                  <!-- <span>
                    <img src="../../../assets/Images/meril-logo.png" style="width: 30%;" class="LoginMerilLogo">
                  </span> -->
                  <!-- Welcome -->
                  <h2 class="LoginH2"><b>Welcome</b></h2>
                  <!-- Login -->
                  <h5 class="LoginH5"><b>Log In</b></h5>
                  <!-- Username -->
                  <div class="mb-2">
                    <input type="text" class="form-control input-field" name="userName" id="userName" formControlName="username" placeholder="Enter UserName" autofocus tabindex="1">
                  </div>
                  <!-- Password -->
                  <div class="mb-1">
                    <input [type]="type" class="form-control input-field" name="password" id="password" formControlName="password" placeholder="Password" tabindex="1">
                  </div>
                  <!-- Forgot Password -->
                  <div class="text-end">
                    <p><a href="#" class="LoginPTag" [routerLinkActive]="['router-link-active']" [routerLink]="['/forgotPassword']" [ariaDisabled]="loading">Have trouble logging in?</a></p> 
                  </div>
                  <!-- Captcha -->
                  <app-captcha [config]="captchaConfig" formControlName="captchaData"/>
                  <!-- Buttons -->
                  <div class="row mt-3">
                    <div class="col">
                      <button type="submit" class="btn btn-outline-success form-control" tabindex="1" [disabled]="loading"><b>Submit<span class="spinner-border spinner-border-sm" *ngIf="loading" role="status" aria-hidden="true"></span></b></button>
                    </div>
                    <div class="col">
                      <button type="reset" class="btn btn-outline-danger form-control" [disabled]="loading"><b>Reset</b></button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-sm col-md col-lg col-xl LoginPannel animationSubjectAndRole" *ngIf="!isLoggedIn">
              <div class="card-body p-2 p-md-3 p-lg-4 p-xl-5">
                <app-roles-and-study></app-roles-and-study>
              </div>
            </div>
          </div>
        </div>
        <!-- All Rights reserved - v1.0.1 -->
        <div class="text-center mt-2">
          <p class="cardFooter" style="color: white;"> © 2024 EDC. All rights reserved. <span> v1.0.4</span></p>
        </div>
      </div>
    </div>
  </div>
</section>  