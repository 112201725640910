<form *ngIf="formValue" [formGroup]="formValue" (ngSubmit)="login()" novalidate>
  <!-- Meril Logo -->
  <!-- <span><img src="../../../assets/Images/meril-logo.png" class="LoginMerilLogo"></span> -->
  <!-- Study & Role -->
  <h5 class="LoginH5"><b>Study & Role</b></h5>
  <!-- Select Study -->
  <div class="mb-2">
    <select class="form-select" id="study" name="study" formControlName="study" aria-label="Floating label select example" (change)="onItemSelect($event.target)">
      <option [ngValue]="null" selected disabled>Select Study</option>
      <option *ngFor="let e of allData" [value]="e.studyId">{{e.name}}</option>
    </select>
  </div>
  <!-- Select Role -->
  <div class="mb-1">
    <select name="role" formControlName="role" class="form-select" id="role" aria-label="Floating label select example">
      <option [ngValue]="null" selected>Select Role</option>
      <option *ngFor="let r of roleData" [value]="r.id">{{r.name}}</option>
    </select>
  </div>
  <!-- Select Study & Role - Buttons -->
  <div class="row mt-4">
    <div class="col text-end">
      <button type="submit" class="btn btn-outline-success" [disabled]="loading"><b>Submit 
        <span class="spinner-border spinner-border-sm" *ngIf="loading" role="status" aria-hidden="true"></span></b></button>                     
    </div>
    <div class="col">
      <button type="reset" class="btn btn-outline-danger" (click)="cancelLogin()" [disabled]="loading"><b>Cancel</b></button>
    </div>
  </div>
</form>