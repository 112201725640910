import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { DynamicIdTableComponent } from "./dynamic-id-table.component";
@NgModule({
    declarations: [
        DynamicIdTableComponent
    ],
    imports: [
        CommonModule,
        NgbModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule
    ],
    exports: [
        DynamicIdTableComponent
    ]
})
export class DynamicIdTableModule {

}