import { Role } from "../models/Role";
import { HttpHeaders } from '@angular/common/http';
import { QueryStatusEnum, QueryTypeEnum } from "./enums/queryEnums";
import { re } from "mathjs";
import { DatePipe } from "@angular/common";

export function getTokenData(key: string) {
    return sessionStorage.getItem(key);
}
export function cloneArray(json:any){
    let data = JSON.stringify(json)
    return JSON.parse(data);
}
export function getDateTime(file:any,role:any,studyName:any){
    let d = new Date()
    var year = d.getFullYear();
    var month = (new Date().getMonth() + 1);
    var day = d.getDate();
    var hours = d.getHours();
    var minutes = d.getMinutes();
    var seconds = d.getSeconds();
    var dateStyle = day + "/" + month + "/" + year + "_" + hours + ":" + minutes + ":" + seconds
    let fileName = null;
    // if(role == 6){
    //     fileName = file + "_" + dateStyle
    // }
    if(studyName == null){
        fileName = file + "_" + dateStyle;
    }
    else{
        fileName = file + "_" + studyName + "_" + dateStyle
    }
    return fileName
}
export function hideDataWithTypeAndStatusAndRole(type:any,status:any,roles:any){
    if(type == QueryTypeEnum.SYSTEM_QUERY){
        if( [QueryStatusEnum.Open, QueryStatusEnum.Response, QueryStatusEnum.Requery].includes(status) ){
            if(roles == Role.CRC || roles == Role.CRA || 
                // roles == Role.Monitor || 
                roles == Role.DM || roles == Role.Investigator){
                return false
            }
            return true
        }else{
            return true;
        }
    }else if(type == QueryTypeEnum.CRA_QUERY){
        if(status == QueryStatusEnum.Open){
            if(roles == Role.CRC || roles == Role.CRA || roles == Role.Investigator){
                return false
            }
            return true
        }else{
            if(roles == Role.CRA || roles == Role.Investigator){
                return false
            }
        }
        return true
    }
    // else if(type == QueryTypeEnum.DM_QUERY){
    //     if(status == QueryStatusEnum.Open){
    //         if(roles == Role.CRC || 
    //             // roles == Role.Monitor || 
    //             roles == Role.Investigator){
    //             return false
    //         }
    //         return true
    //     }else{
    //         if(
    //             // roles == Role.Monitor ||
    //             roles == Role.Investigator){
    //             return false
    //         }
    //     }
    //     return true
    // }
    else if([QueryTypeEnum.DM_QUERY,QueryTypeEnum.ML_QUERY].includes(type)){
        if( [QueryStatusEnum.Open, QueryStatusEnum.Response, QueryStatusEnum.Requery].includes(status) ){
            if(roles == Role.CRC || roles == Role.DM || roles == Role.Investigator){
                return false
            }
            return true
        }else{
            if(roles == Role.DM || roles == Role.Investigator){
                return false
            }
        }
        return true
    }else if(type == QueryTypeEnum.INV_QUERY){
        if(status == QueryStatusEnum.Open){
            if(roles == Role.CRC || roles == Role.Investigator){
                return false
            }
            return true
        }else{
            if(roles == Role.Investigator){
                return false
            }
        }
        return true
    }
    return true
}

export function getTimeZoneOffset(): string {
    const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    console.log('Client Timezone:', clientTimezone);
    return clientTimezone;
}

export function getTimeZoneHeader(){
    return new HttpHeaders().set('Timezone',getTimeZoneOffset())
  }


  export function getGMTDate(time:string) {
    if(time){
      var local = new Date(time);
      var offset = local.getTimezoneOffset();
      var utc = new Date(local.getTime() + offset * 60000);
      let datePipe = new DatePipe('EN')
      let test:any = datePipe.transform(utc.toISOString(),"yyyy-MM-dd HH:mm:ss.SSS")
      return  test.toString() || null;
    }
    return '';
  }