import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { distinctUntilChanged } from 'rxjs';
import { Role } from 'src/app/models/Role';

import { SiteSubjectStoreService } from 'src/app/store/services/site-subject-store.service';
import { VisitFormStoreService } from 'src/app/store/services/visit-form-store.service';

@Component({
  selector: 'dynamic-filter',
  templateUrl: './dynamic-filter.component.html',
  styleUrls: ['./dynamic-filter.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicFilterComponent {

  @Input() isShowSiteFilter: boolean = false;
  @Input() isShowSubjectFilter: boolean = false;
  @Input() isShowVisitFilter: boolean = false;
  @Input() isShowFormFilter: boolean = false;

  @Input() isShowUploadButton: boolean = false;
  @Input() isUploadCancel: boolean = false;
  @Input() isUploadDataValidated: boolean = false;

  @Output() onSelectedFilterData: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClickUploadFile: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClickClearFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClickCancelFile: EventEmitter<any> = new EventEmitter<any>();

  subjectList: Array<any> = [];
  siteList: Array<any> = [];
  visitList: Array<any> = [];
  formList: Array<any> = [];
  filterForm!: FormGroup;
  subjectDropDownConfiguration!: IDropdownSettings;
  siteDropDownConfiguration!: IDropdownSettings;
  visitDropdownConfiguration!: IDropdownSettings;
  formDropdownConfiguration!: IDropdownSettings;

  allSubjectDetails: any;
  allFormDetails: any;  

  isSingleSelection: boolean = false  
  crc: number = Role.CRC;
  roles: number = -1;

  constructor(private changeDetectorRef: ChangeDetectorRef,
              private formBuilder: FormBuilder,
              private siteSubjectStoreService: SiteSubjectStoreService,
              private visitFormStoreService: VisitFormStoreService,
            private toastr: ToastrService) {
    this.filterForm = this.formBuilder?.group({
      subject: new FormControl({}),
      site: new FormControl({}),
      visit: new FormControl({}),
      form: new FormControl({})
    })
  }

  ngOnInit(): void {

    this.roles = Number(sessionStorage.getItem('role'));
    this.crc = Role.CRC;
    this.setDropDownConfiguration();
    this.subscribeSiteSubjectStore();
    this.subscribeVisitFormStore();
    this.subscribeLogFormStore();
    
    this.manipulateSubjectList();
    this.manipulateFormList();
    this.detectChanges();
  }

  setDropDownConfiguration = (): void => {
    this.subjectDropDownConfiguration = this.getDropDownConfiguration('id', 'subjectId', this.isSingleSelection, true);
    this.siteDropDownConfiguration = this.getDropDownConfiguration('id', 'name', this.isSingleSelection, true);
    this.visitDropdownConfiguration = this.getDropDownConfiguration('id', 'name', this.isSingleSelection, true);    
    this.formDropdownConfiguration = this.getDropDownConfiguration('formId', 'formName', this.isSingleSelection, true);    
  }

  subscribeSiteSubjectStore() {
  this.siteSubjectStoreService.siteSubjects$.subscribe((data) => {
      this.extractSiteSubjectData(data);
      this.changeDetectorRef.detectChanges();
    });
  }

  extractSiteSubjectData(data: any): void {
    this.siteList = Object.entries(data?.sites || {}).map(([siteId, siteData]: [string, any]) => ({
      id: siteId,
      name: siteData.name || '',
      subjects: siteData.subjects || []
    }));

    this.subjectList = [];
      this.subjectList = this.siteList.reduce((acc, site) => {
      const subjects = data?.sites?.[site.id]?.subjects || [];
      return acc.concat(subjects);
    }, []);
    this.allSubjectDetails = this.subjectList;
  }

  extractVisitFormData(data: any): void {
    if (!data?.visit) return;

    const newVisitList = Object.entries(data.visit).map(([visitId, visit]: [string, any]) => ({
        id: visitId,
        name: visit.name || '',
        forms: visit.forms || []
    }));

    this.visitList = [...this.visitList, ...newVisitList];

    const newFormList = newVisitList.reduce((acc, visit) => {
        const forms = data.visit[visit.id]?.forms || [];
        return acc.concat(forms);
    }, []);

    this.formList = [...this.formList, ...newFormList];

    this.allFormDetails = [...this.formList];
  }
  
  subscribeVisitFormStore() {
    this.visitFormStoreService.visitForms$.subscribe((data) => {
      this.extractVisitFormData(data);
      this.changeDetectorRef.detectChanges();
    });
  }

  subscribeLogFormStore() {
    this.visitFormStoreService.logForms$.subscribe((data) => {
      this.extractVisitFormData(data);
      this.changeDetectorRef.detectChanges();
    });
  }

  getDropDownConfiguration = (idField: string, textField: string, isSingleSelection: boolean, allowSearchFilter: boolean): any => {
    return {
      singleSelection: isSingleSelection,
      idField: idField,
      textField: textField,
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect',
      itemsShowLimit: 2,
      allowSearchFilter: allowSearchFilter,
    }
  }

  resetDropDownSelection = (formControlName: string): void => {
    this.filterForm?.get(formControlName)?.reset();
  }

  manipulateSubjectList() {
    this.filterForm.get('site')?.valueChanges.pipe(distinctUntilChanged()).subscribe((selectedSites) => {
        this.updateSubjectList(selectedSites);
        this.resetDropDownSelection('subject');
        this.resetDropDownSelection('visit');
        this.resetDropDownSelection('form');
      });
    this.filterForm.get('subject')?.valueChanges.pipe(distinctUntilChanged()).subscribe((selectedSubjects) => {
      this.resetDropDownSelection('visit');
      this.resetDropDownSelection('form');
    });
  }

  manipulateFormList() {
    this.filterForm.get('visit')?.valueChanges.pipe(distinctUntilChanged()).subscribe((selectedVisits) => {
      this.updateFormList(selectedVisits);
      this.resetDropDownSelection('form');
    });
  }

  updateSubjectList(selectedSites: any[]): void {
    if (!selectedSites || selectedSites.length === 0) {
      this.subjectList = this.allSubjectDetails;
    } else {
      this.subjectList = selectedSites.reduce((acc, site) => {
        const matchedSite = this.siteList.find(s => s.id === site.id);
        return matchedSite ? acc.concat(matchedSite.subjects) : acc;
      }, []);
    }
    this.detectChanges();
  }

  updateFormList(selectedVisits: any[]): void {
    if (!selectedVisits || selectedVisits.length === 0) {
      this.formList = this.allFormDetails
    } else {
      this.formList = selectedVisits.reduce((acc, visit) => {
        const matchedVisit = this.visitList.find(v => v.id === visit.id);
        return matchedVisit ? acc.concat(matchedVisit.forms) : acc;
      }, []);
    }
    this.detectChanges();
  }

  onApplyFilter = (): void => {    
    const eventData = {
      subjects: Array.isArray(this.filterForm?.get('subject')?.value) &&this.filterForm?.get('subject')?.value?.map((data: any) => data?.id)?.join(',') || [],
      sites: Array.isArray(this.filterForm?.get('site')?.value) && this.filterForm?.get('site')?.value?.map((data: any) => data?.id)?.join(',') || [],
      visits: Array.isArray(this.filterForm?.get('visit')?.value) && this.filterForm?.get('visit')?.value?.map((data: any) => data?.id)?.join(',') || [],
      forms: Array.isArray(this.filterForm?.get('form')?.value) && this.filterForm?.get('form')?.value?.map((data: any) => data?.formId)?.join(',') || []
    }
    
    this.onSelectedFilterData?.emit(eventData);
    this.setDropDownConfiguration();
  }

  uploadFile = (): void => {
    const selectedSite = this.filterForm.get('site')?.value;
    const selectedSubject = this.filterForm.get('subject')?.value;
    const selectedVisit = this.filterForm.get('visit')?.value;
    const selectedForm = this.filterForm.get('form')?.value;

    const eventData = {
      selectedSite: selectedSite,
      selectedSubject: selectedSubject,
      selectedVisit: selectedVisit,
      selectedForm: selectedForm
    }
    this.onClickUploadFile?.emit(eventData);
    this.isSingleSelection = true;
    this.setDropDownConfiguration();
    if (this.isUploadDataValidated) {
      this.isUploadCancel = true;
    } else {      
      this.isSingleSelection = false;
      this.setDropDownConfiguration();
    }
  }

  onClearFilter = (): void => {
    ['subject', 'site', 'visit', 'form'].forEach(this.resetDropDownSelection.bind(this));
    this.onSelectedFilterData?.emit({ subjects: [], sites: [], visits: [], forms: [] });
  }

  detectChanges = (): void => {
    try { 
      this.changeDetectorRef?.detectChanges();
    } catch (error) {
    }
  }

  cancelUpload = (data: any): void => {
    this.isUploadCancel = false;
    this.isSingleSelection = false;
    this.setDropDownConfiguration();
    this.onClickCancelFile?.emit(data);
  }
}

