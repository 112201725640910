
export enum NotificationSendTo{
  ROLES = 1, USERS,
};

export enum NotificationType {
  EMAIL = 1, SMS,
};

export enum NotificationStatus {
  ACTIVE = 1, INACTIVE,
};