import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { baseURL, VERSION } from 'src/app/constant/constant';
import { User } from '../models/user';
import { getTimeZoneHeader } from '../constant/globalFunction';
import { User as UserIdName } from '../types/IdNameType';
import type { ResponseUser } from '../models/response/responseUser';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  getAllData(pageNo:any,pageSize:any,studyId:any,userId:any,roleId:any,data?:any): Observable<any> {
    return this.http.put(`${baseURL}users/${VERSION}/list?pageNo=${pageNo}&pageSize=${pageSize}&studyId=${studyId}&userId=${userId}&roleId=${roleId}`,data);
  }

  addData(data: User): Observable<any> {
    return this.http.post(`${baseURL}users/${VERSION}`, data);
  }

  updateData(data: User): Observable<any> {
    return this.http.put(`${baseURL}users/${VERSION}`, data);
  }

  disableUser(id: string,body:any): Observable<any> {
    return this.http.put(`${baseURL}users/${VERSION}/status/${id}`,body);
  }

  deleteUser(id: string): Observable<any> {
    return this.http.delete(`${baseURL}users/${VERSION}/delete?` + `id=` + id);
  }

  getRoleData(): Observable<any> {
    return this.http.get(`${baseURL}roles/${VERSION}/list`);
  }

  getStudyData(): Observable<any> {
    return this.http.get(`${baseURL}study/${VERSION}/studySiteList`);
  }

  resetPassword(email:any): Observable<any> {
    return this.http.post(`${baseURL}users/${VERSION}/resetPassword?email=${email}`,email);
  }

  resendPassword(email:any): Observable<any> {
    return this.http.post(`${baseURL}users/${VERSION}/resendPassword?email=${email}`,email);
  }

  changePassword(data:any): Observable<any> {
    return this.http.put(`${baseURL}users/${VERSION}/changePassword`,data);
  }

  getUserIdAndUserName(): Observable<any> {
    return this.http.get(`${baseURL}users/${VERSION}/userName`);
  }

  getUserIdAndUserNameData(): Observable<{status:number;responseObject: ResponseUser[];}> {
    return this.http.get<{status: number; responseObject: ResponseUser[]; }>(`${baseURL}users/${VERSION}/userName`);
  }

  getUserIdAndUserNameByRoleId(siteId:any,queryType:number): Observable<any> {
    return this.http.get(`${baseURL}users/${VERSION}/idAndUserName?siteId=${siteId}&queryType=${queryType}`);
  }

  getUserFilterList(studyId:any,roleId:any):Observable<any>{
    return this.http.get(`${baseURL}users/${VERSION}/userFilterList?studyId=${studyId}&roleId=${roleId}`)
  }

  lockUnlockUser(data:any): Observable<any> {
    return this.http.put(`${baseURL}users/${VERSION}/lock`,data);
  }

  getAllStudyData(): Observable<any> {
    return this.http.get(`${baseURL}study/${VERSION}/studySiteRels`);
  }

  getUserDataExport(studyId:any):Observable<any>{
    let url:string = `${baseURL}users/${VERSION}/export?studyId=${studyId}`
    return this.http.get(url,{headers:getTimeZoneHeader()});
  }

  getUserBySites(siteIds: number[]): Observable<{ status: number; message: string; responseObject: UserIdName[];}>{
    let url = `${baseURL}users/${VERSION}/site`;
    if(siteIds.length !== 0){
      url = `${baseURL}users/${VERSION}/site?sites=${siteIds.join(',')}`
    }
    return this.http.get<{status:number,message:string,responseObject:UserIdName[]}>(url)
  }

}
