
<div class="container-fluid">
    <div class="row mb-1">
      <div class="col-sm col-md-9 col-lg-9 col-xl-8">
        <h3>Notification Configuaration</h3>
      </div>
      <div class="col-sm col-md-2 col-lg-2 col-xl-2 text-end">
        <button class="btn btn-outline-danger mt-2" type="button" (click)="onCancel()"><b>Back</b></button>
      </div>
      <div class="col-sm col-md-1 col-lg-1 col-xl-2"></div>
    </div>
  <form class="mb-3" [formGroup]="notificationConfiguration">
     <!-- Notifiaction Name & Status & Sites -->
    <div class="row mt-2">
      <div class="col-sm col-md-4 col-lg-5 col-xl-4">
        <div class="form-floating">
          <input id="notificationName" type="text" class="form-control" placeholder="Notification Name" formControlName="name">
          <label for="notificationName">Notifiaction Name</label>
        </div>
        <div *ngIf="notificationConfiguration.controls.name?.touched && notificationConfiguration.controls.name?.hasError('required')">
          <p class="text-danger"> &nbsp;&nbsp;Notification Name is required</p>
        </div>
      </div>
      <div class="col-sm col-md-3 col-lg-3 col-xl-3">
        <b class="boldText">Status</b><br>
        <div class="form-check form-check-inline">
          <input id="ActiveStatus" class="form-check-input" type="radio" name="status" [value]="1" formControlName="status">
          <label for="ActiveStatus" class="form-check-label" >
            Active
          </label>
        </div>
        <div class="form-check form-check-inline">
          <input id="InactiveStatus" class="form-check-input" type="radio" name="status" [value]="0" formControlName="status">
          <label for="InactiveStatus" class="form-check-label" >
            Inactive
          </label>
        </div>
      </div>
      <div class="col-sm col-md-4 col-lg-3 col-xl-3" formGroupName="multiSelect">
        <b class="boldText">Site</b><br>
        <div>
          <ng-multiselect-dropdown 
            class="dropdown-list" 
            placeholder="Site" 
            formControlName="siteIds"
            [settings]="sitesDropDownSettings"
            [data]="siteDropdownList"
            (click)="siteDropDownSelect = true"
            (onDropDownClose)="onSiteDropDownClose()">
          </ng-multiselect-dropdown>
        </div>
        <div class="col-3"></div>
        <div *ngIf="notificationConfiguration.controls.multiSelect.controls.siteIds?.touched && notificationConfiguration.controls.multiSelect.controls.siteIds.hasError('required')">
          <p class="text-danger"> &nbsp;&nbsp;site is required</p>
        </div>
      </div>
      <div class="col-sm col-md-1 col-lg-1 col-xl-2"></div>
    </div>
    <!-- Notifiaction Media -->
    <!-- <div class="row">
      <div class="col-sm col-md-11 col-lg-10 col-xl-10">
        <b class="boldText">Notification Media</b><br>
        <div class="form-check form-check-inline">
          <input id="sendMediaEmail" class="form-check-input" type="radio" name="type" [value]="1" checked formControlName="type">
          <label for="sendMediaEmail" class="form-check-label">
            Email
          </label>
        </div>             
      </div>
      <div class="col-sm col-md-1 col-lg-2 col-xl-2"></div>
    </div> -->
    <!-- Notifiaction Descriptions -->
    <div class="row mt-3" >
      <div class="col-sm col-md-11 col-lg-11 col-xl-10">
      <div class="form-floating">
        <textarea class="form-control textAreaSizing" placeholder="Notifiaction Description" id="floatingTextarea2" formControlName="description"></textarea>
        <label for="floatingTextarea2">Notification Description</label>
        </div>
        <div *ngIf="notificationConfiguration.get('description')?.touched && notificationConfiguration.get('description')?.hasError('required')">
          <p class="text-danger"> Notification Description is required </p>
        </div>
      </div>
      <div class="col-sm col-md-1 col-lg-1 col-xl-2"></div>
    </div>
    <!-- Notifiaction Log Form Dropdown & Email Template-->
    <div class="row mt-3">
      <div class="col-sm col-md col-lg-4 col-xl-4" formGroupName="multiSelect">
        <b class="boldText">Log Form</b><br>
        <div style="line-height: 28px;" class="mt-2">
          <ng-multiselect-dropdown
          class="dropdown-list" 
          formControlName="formIds" 
          placeholder="Log form" 
          [settings]="logFormDropdownSetting"
          [data]="logFormDropdownList"
        > </ng-multiselect-dropdown>
        </div>
        <div *ngIf="notificationConfiguration.controls.multiSelect.controls.formIds.touched && notificationConfiguration.controls.multiSelect.controls.formIds.hasError('required')">
          <div class="text-danger" >Form is required</div>
        </div>
      </div>
      <div class="col-sm col-md col-lg-4 col-xl-3">
        <b class="boldText">Email template</b><br>
        <select class="form-select mt-2" aria-label="Default select example" formControlName="templateId">
          <option [value]="null" [disabled]="true" >Email Template</option>
          <option *ngFor="let item of emailTemplateDropdownList" [value]="item.id">{{item.name}}</option>
        </select>
        <div *ngIf="notificationConfiguration.controls.templateId?.touched && notificationConfiguration.controls.templateId?.hasError('required')">
          <p class="text-danger">Email Template is Required</p>
        </div>
      </div>
      <div class="col-sm col-md col-lg-3 col-xl-3">
        <b class="boldText">Notification Media</b><br>
        <div class="form-check form-check-inline mt-2">
          <input id="sendMediaEmail" class="form-check-input" type="radio" name="type" [value]="1" checked formControlName="type">
          <label for="sendMediaEmail" class="form-check-label">
            Email
          </label>
        </div>  
      </div>
      <div class="col-sm col-md-1 col-lg-1 col-xl-2"></div>
    </div>
    <!-- Notifiaction To Section -->
    <div class="row mt-3">
      <div class="col-sm col-md-1 col-lg-1 col-xl-1">
        <b>to : </b> <sup class="text-danger" *ngIf="notificationConfiguration.controls.toList.touched && notificationConfiguration.controls.toList.hasError('required')" >required **</sup>
        <!-- <div >
          <p class="text-danger"> To Role is required </p>
        </div> -->
      </div>
      <div class="col-sm col-md-1 col-lg-1 col-xl-1" *ngIf="toListControl.value == 1">
        <b class="boldText">Roles</b>
      </div>
      <div class="col-sm col-md-1 col-lg-1 col-xl-1" *ngIf="toListControl.value == 2">
        <b class="boldText">Users</b>
      </div>
      <div class="col-sm col-md-2 col-lg-1 col-xl-1">
        <div class="form-check form-check-inline">
          <input id="toRoles" class="form-check-input" type="radio" [value]="1" formControlName="toList" >
          <label for="toRoles" class="form-check-label">Role</label>
        </div>
      </div>
      <div class="col-sm col-md-2 col-lg-1 col-xl-1">
        <div class="form-check form-check-inline">
          <input id="toUsers" class="form-check-input" type="radio" [value]="2" formControlName="toList" >
          <label for="toUsers" class="form-check-label">User</label>
        </div>
      </div>
      <!-- <div class="col-sm col-md-1 col-lg-2 col-xl-1 text-end" *ngIf="toListControl.value == 1">
        <b class="boldText">Roles</b><br>
      </div>
      <div class="col-sm col-md-1 col-lg-2 col-xl-1 text-end" *ngIf="toListControl.value == 2">
        <b class="boldText">Users</b><br>
      </div> -->
      <div class="col-sm col-md-5 col-lg-4 col-xl-3" formGroupName="multiSelect" *ngIf="toListControl.value == 1" >
        <div>
          <ng-multiselect-dropdown 
            class="dropdown-list" 
            placeholder="Roles" 
            formControlName="toRoles" 
            [settings]="toRoleDropDownSettings" 
            [data]="toRoleDropdownList">
          </ng-multiselect-dropdown>
        </div>
        <div *ngIf="toListControl.value == 1 && notificationConfiguration.controls.multiSelect.controls.toRoles.touched && notificationConfiguration.controls.multiSelect.controls.toRoles.hasError('required')">
          <div class="text-danger" >Role is required</div>
        </div>
      </div>
      <div class="col-sm col-md-5 col-lg-4 col-xl-3" formGroupName="multiSelect" *ngIf="toListControl.value == 2" >
        <div>
          <ng-multiselect-dropdown 
            class="dropdown-list" 
            placeholder="Users" 
            formControlName="toUsers" 
            [settings]="toUserDropDownSettings" 
            [data]="toUserDropdownList">
          </ng-multiselect-dropdown>
        </div>
        <div *ngIf="toListControl.value == 2 && notificationConfiguration.controls.multiSelect.controls.toUsers.touched && notificationConfiguration.controls.multiSelect.controls.toUsers.hasError('required')">
          <div class="text-danger" >User is required</div>
        </div>
      </div>
      <div class="col-sm col-md-1 col-lg-4 col-xl-5"></div>
    </div>
    <!-- Notifiaction CC Section -->
    <div class="row mt-3">
      <div class="col-sm col-md-1 col-lg-1 col-xl-1">
        <b>cc :</b>
      </div>
      <div class="col-sm col-md-1 col-lg-1 col-xl-1" *ngIf="ccListControl.value == 1">
        <b class="boldText">Roles</b>
      </div>
      <div class="col-sm col-md-1 col-lg-1 col-xl-1" *ngIf="ccListControl.value == 2">
        <b class="boldText">Users</b>
      </div>
      <div class="col-sm col-md-2 col-lg-1 col-xl-1">
        <div class="form-check form-check-inline">
          <input id="ccRoles" class="form-check-input" type="radio" name="ccList" [value]="1" formControlName="ccList" >
          <label for="ccRoles" class="form-check-label">Role</label>
        </div>
      </div>
      <div class="col-sm col-md-2 col-lg-1 col-xl-1">
        <div class="form-check form-check-inline">
          <input id="ccUsers" class="form-check-input" type="radio" name="ccList" [value]="2" formControlName="ccList" >
          <label for="ccUsers" class="form-check-label">User</label>
        </div>
      </div>
      <!-- <div class="col-sm col-md-1 col-lg-2 col-xl-1 text-end" *ngIf="ccListControl.value == 1">
        <b class="boldText">Roles</b><br>
      </div>
      <div class="col-sm col-md-1 col-lg-2 col-xl-1 text-end" *ngIf="ccListControl.value == 2">
        <b class="boldText">Users</b><br>
      </div> -->
      <div class="col-sm col-md-5 col-lg-4 col-xl-3" formGroupName="multiSelect" *ngIf="ccListControl.value == 1" >
        <div>  
          <ng-multiselect-dropdown 
            class="dropdown-list" 
            placeholder="Roles" 
            formControlName="ccRoles" 
            [settings]="ccRoleDropDownSettings" 
            [data]="ccRoleDropdownList">
          </ng-multiselect-dropdown>
        </div>
        <div *ngIf="ccListControl.value == 1 && notificationConfiguration.controls.multiSelect.controls.ccRoles.touched && notificationConfiguration.controls.multiSelect.controls.ccRoles.hasError('required')">
          <div class="text-danger" >Role is required</div>
        </div>
      </div>
      <div class="col-sm col-md-5 col-lg-4 col-xl-3" formGroupName="multiSelect" *ngIf="ccListControl.value == 2" >
        <div>
          <div>
            <ng-multiselect-dropdown 
              class="dropdown-list" 
              placeholder="Users" 
              formControlName="ccUsers" 
              [settings]="ccUserDropDownSettings" 
              [data]="ccUserDropdownList">
            </ng-multiselect-dropdown>
          </div>
          <div *ngIf="ccListControl.value == 2 && notificationConfiguration.controls.multiSelect.controls.ccUsers.touched && notificationConfiguration.controls.multiSelect.controls.ccUsers.hasError('required')">
            <div class="text-danger" >User is required</div>
          </div>
        </div>
      </div>
      <div class="col-sm col-md-1 col-lg-4 col-xl-5"></div>
    </div>
    <!-- Notifiaction BCC Section -->
    <div class="row mt-3">
      <div class="col-sm col-md-1 col-lg-1 col-xl-1" style="padding-right: 0px;">
        <b>bcc :</b>
      </div>
      <div class="col-sm col-md-1 col-lg-1 col-xl-1" *ngIf="bccListControl.value == 1">
        <b class="boldText">Roles</b>
      </div>
      <div class="col-sm col-md-1 col-lg-1 col-xl-1" *ngIf="bccListControl.value == 2">
        <b class="boldText">Users</b>
      </div>
      <div class="col-sm col-md-2 col-lg-1 col-xl-1">
        <div class="form-check form-check-inline">
          <input id="bccRoles" class="form-check-input" type="radio" name="bccList" [value]="1" formControlName="bccList" >
          <label for="bccRoles" class="form-check-label">Role</label>
        </div>
      </div>  
      <div class="col-sm col-md-2 col-lg-1 col-xl-1">
        <div class="form-check form-check-inline">
          <input id="bccUsers" class="form-check-input" type="radio" name="bccList" [value]="2" formControlName="bccList">
          <label for="bccUsers" class="form-check-label">User</label>
        </div>
      </div>
      <!-- <div class="col-sm col-md-1 col-lg-2 col-xl-1 text-end" *ngIf="bccListControl.value == 1">
        <b class="boldText">Roles</b><br>
      </div>
      <div class="col-sm col-md-1 col-lg-2 col-xl-1 text-end" *ngIf="bccListControl.value == 2">
        <b class="boldText">Users</b><br>
      </div> -->
      <div class="col-sm col-md-5 col-lg-4 col-xl-3" formGroupName="multiSelect" *ngIf="bccListControl.value == 1" >
        <div>
          <ng-multiselect-dropdown 
            class="dropdown-list" 
            placeholder="Roles" 
            formControlName="bccRoles" 
            [settings]="bccRoleDropDownSettings" 
            [data]="bccRoleDropdownList">
          </ng-multiselect-dropdown>
        </div>
        <div *ngIf="bccListControl.value == 1 && notificationConfiguration.controls.multiSelect.controls.bccRoles.touched && notificationConfiguration.controls.multiSelect.controls.bccRoles.hasError('required')">
          <div class="text-danger" >Role is required</div>
        </div>
      </div>
      <div class="col-sm col-md-5 col-lg-4 col-xl-3"  formGroupName="multiSelect" *ngIf="bccListControl.value == 2" >
        <div>
          <ng-multiselect-dropdown 
            class="dropdown-list" 
            placeholder="Users" 
            formControlName="bccUsers" 
            [settings]="bccUserDropDownSettings" 
            [data]="bccUserDropdownList" >
          </ng-multiselect-dropdown>
        </div>
        <div *ngIf="bccListControl.value == 2 && notificationConfiguration.controls.multiSelect.controls.bccUsers.touched && notificationConfiguration.controls.multiSelect.controls.bccUsers.hasError('required')">
          <div class="text-danger" >User is required</div>
        </div>
      </div>
      <div class="col-sm col-md-1 col-lg-4 col-xl-5"></div>
    </div>
    <!-- Notifiaction Email Subject -->
    <div class="row mt-3" >
      <div class="col-sm col-md-11 col-lg-11 col-xl-10">
        <div class="form-floating">
          <!-- {{ emailSubject }} -->
          <textarea class="form-control textAreaSizing" placeholder="Email Subject" id="emailSubjectTextArea" [disabled]="true" formControlName="emailSubject"></textarea>
          <label for="emailSubjectTextArea">Email Subject</label>
        </div>
        <div *ngIf="notificationConfiguration.controls.emailSubject.touched && notificationConfiguration.controls.emailSubject.hasError('required')">
          <p class="text-danger"> Email Subject is required </p>
        </div>
      </div>
      <div class="col-sm col-md-1 col-lg-1 col-xl-2"></div>
    </div>
    <!-- Notifiaction Email Body -->
    <div class="row mt-3" >
      <div class="col-sm col-md-11 col-lg-11 col-xl-10">
        <div class="form-floating">
          <!-- {{ emailBody }} -->
          <textarea class="form-control textAreaSizing" placeholder="Email Body" id="emailBodyTextArea" [disabled]="true" formControlName="emailBody"></textarea>
          <label for="emailBodyTextArea">Email Body</label>
        </div>
        <div *ngIf="notificationConfiguration.controls.emailBody.touched && notificationConfiguration.controls.emailBody.hasError('required')">
          <p class="text-danger"> Email Body is required </p>
        </div>
      </div>
      <div class="col-sm col-md-1 col-lg-1 col-xl-2"></div>
    </div>
    <!-- Notifiaction Buttons-->
    <div class="row mt-4">
      <div class="col-sm col-md-11 col-lg-11 col-xl-10">
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
          <span *ngIf="notificationIdcontrol.value === null">
            <button class="btn btn-outline-success form-control me-md-2" type="button"  (click)="onSave()" [disabled]="savingInProgress" >
              Save
              <span *ngIf="savingInProgress"  class="spinner-border spinner-border-sm text-center"></span>
            </button>
          </span>
          <span *ngIf="notificationIdcontrol.value !== null">
            <button class="btn btn-outline-primary me-md-2" type="button" (click)="onUpdate()" [disabled]="savingInProgress" >
              Update
              <span *ngIf="savingInProgress"  class="spinner-border spinner-border-sm text-center"></span>
            </button>
          </span>
          <button class="btn btn-outline-danger" type="button" (click)="onCancel()" >
            Cancel
          </button>
        </div>
      </div>
      <div class="col-sm col-md-1 col-lg-1 col-xl-2"></div>
    </div>
  </form>
</div>

